export default {
    title: { 
        es: 'Colas de atención',
        en: 'Attention queues',
        pt: 'Filas de atenção', 
    },
    getError: { 
        es: 'Ha ocurrido un error al obtener las colas de atención. Por favor, intente nuevamente.',
        en: 'An error has occurred while obtaining the attention queues. Please try again.',
        pt: 'Ocorreu um erro ao obter as filas de atenção. Por favor, tente novamente.', 
    },
    defaultQueue: {
      es: 'Cola por defecto',
      en: 'Default queue',
      pt: 'Fila padrão', 
    },
    table: {
        empty:  {
            en: 'No Attention queues yet',
            es: 'No hay Colas de atención aun',
            pt: 'Nenhuma Fila de atenção ainda'
        },
        addQueue:  {
            en: 'Create Attention Queue',
            es: 'Crear Cola de Atención',
            pt: 'Criar Fila de Atenção'
        },
        queueDeletionTitle: {
            en: 'Delete Attention Queue',
            es: 'Eliminar Cola de Atención',
            pt: 'Excluir Fila de Atenção',
        },
        confirmQueueDeletion: {
            en: 'You are about to delete your Attention Queue, this action is irreversible. Are you sure?',
            es: 'Está a punto de eliminar su Cola de Atención, esta acción es irreversible. ¿Está seguro?',
            pt: 'Você está prestes a excluir seu Fila de Atenção, esta ação é irreversível. Tem certeza?',
        },
        deleteQueue: {
            success: {
              title: {
                es: 'Éxito!',
                en: 'Success',
                pt: 'Sucesso'
              },
              text: {
                es: 'La Cola de Atención se ha eliminado exitosamente.',
                en: 'Attention Queue deleted successfully.',
                pt: 'Fila de Atenção excluída com sucesso.'
              }
            },
            error: {
              title: {
                es: 'Error',
                en: 'Error',
                pt: 'Erro'
              },
              text: {
                es:
                  'No se pudo eliminar la Cola de Atención. Por favor, intente nuevamente.',
                en: 'The Attention Queue was not deleted. Please try again later.',
                pt: 'A Fila de Atenção não pôde ser excluída. Por favor, tente novamente.'
              }
            }
          },
    },
    detail: {
        name: {
            es: 'Nombre',
            en: 'Name',
            pt: 'Nome'
          },
          description: {
            es: 'Descripción',
            en: 'Description',
            pt: 'Descrição'
          },
          channels: {
            es: 'Canales',
            en: 'Channels',
            pt: 'Canais'
          },
          noDialogDetectedTakeover: {
            es: 'Derivar a esta cola cuando el asistente virtual no está entendiendo al cliente',
            en: 'Takeover to this queue when the virtual assistant is not understanding',
            pt: 'Efetuar takeover nessa fila quando o assistente virtual não estiver entendendo',
          },
          savedQueue: {
            success: {
              title: {
                es: 'Éxito!',
                en: 'Success',
                pt: 'Sucesso'
              },
              text: {
                es: 'La Cola de Atención se ha guardado exitosamente.',
                en: 'Attention Queue saved successfully.',
                pt: 'Fila de Atenção salva com sucesso.'
              }
            },
            error: {
              title: {
                es: 'Error',
                en: 'Error',
                pt: 'Erro'
              },
              text: {
                es:
                  'No se pudo guardar la Cola de Atención. Por favor, intente nuevamente.',
                en: 'The Attention Queue was not saved. Please try again later.',
                pt: 'A Fila de Atenção não pôde ser salva. Por favor, tente novamente.'
              }
            }
          },
          saveQueue: {
            es: 'Guardar',
            en: 'Save',
            pt: 'Salvar'
          },
          backToQueuesList: {
            es: 'Volver a lista de Colas de Atención',
            en: 'Back to Attention queues list',
            pt: 'Voltar para a lista de Fila de Atenção',
          },
          sentimentAnalysis: {
            es: 'Derivar a esta cola por analisis de sentimiento',
            en: 'Takeover to this queue by sentiment analysis',
            pt: 'Efetuar takeover nessa fila por análise de sentimento',
          }
    }
}