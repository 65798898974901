export default {
  bots: {
    title: {
      es: 'Bots',
      en: 'Bots',
      pt: 'Bots'
    },
    table: {
      bot: {
        es: 'Bot',
        en: 'Bot',
        pt: 'Bot'
      },
      creation: {
        es: 'Creación',
        en: 'Creation',
        pt: 'Criação'
      }
    }
  },
  channels: {
    title: {
      es: 'Canal de envío',
      en: 'Delivery channel',
      pt: 'Canal de entrega'
    },
    table: {
      channel: {
        es: 'Canal de envío',
        en: 'Delivery channel',
        pt: 'Canal de entrega'
      },
      creation: {
        es: 'Creación',
        en: 'Creations',
        pt: 'Criações'
      }
    },
    email: {
      settings: {
        title: {
          es: 'Configuración',
          en: 'Settings',
          pt: 'Configuração'
        },
        version: {
          title: {
            es: 'Staging',
            en: 'Staging',
            pt: 'Staging'
          }
        },
        email: {
          title: {
            es: 'Correo electrónico',
            en: 'Email',
            pt: 'E-mail'
          },
          subtitle: {
            es: 'Ingresa el contenido del correo electrónico',
            en: 'Insert the email body',
            pt: 'Insira o corpo do e-mail'
          },
          from: {
            es: 'De',
            en: 'From',
            pt: 'De'
          },
          fromPlaceholder: {
            es: 'miemail@empresa.com',
            en: 'myemail@company.com',
            pt: 'meuemail@empresa.com'
          },
          subject: {
            es: 'Asunto',
            en: 'Subject',
            pt: 'Sujeito'
          },
          htmlPlaceholder: {
            es: 'Ingrese contenido del email',
            en: 'Insert email body',
            pt: 'Inserir corpo de e-mail'
          }
        },
        audience: {
          title: {
            es: 'Audiencia',
            en: 'Audience',
            pt: 'Audiência'
          },
          subtitle: {
            es:
              'Carga o ingresa la lista de correos electrónicos que recibirán la encuesta',
            en:
              'Upload or enter the list of emails that will receive the survey',
            pt: 'Carregue ou digite a lista de e-mails que receberão a pesquisa'
          },
          removeAll: {
            es: 'Eliminar todo',
            en: 'Remove all',
            pt: 'Remover tudo'
          },
          form: {
            number: {
              es: 'email',
              en: 'email',
              pt: 'e-mail'
            },
            emailExists: {
              es: 'El email ya está listado',
              en: 'Email already in list',
              pt: 'E-mail já na lista'
            },
            errorEmail: {
              es: 'Email no es válido',
              en: 'Email is not valid',
              pt: 'O e-mail não é válido'
            },
            errorEmails: {
              es: 'Listado de emails invalidos',
              en: 'List of invalid emails',
              pt: 'Lista de e-mails inválidos'
            },
            loadTemplateAgain: {
              es: 'Cargar de nuevo',
              en: 'Load again',
              pt: 'Carregue novamente'
            },
            ignoreInvalid: {
              es: 'Ignorar los inválidos',
              en: 'Ignore invalid',
              pt: 'Ignorar inválido'
            },
            addNumber: {
              label: {
                es: 'agregar email',
                en: 'add email',
                pt: 'adicionar e-mail'
              },
              placeholder: {
                es: 'nombre@empresa.com',
                en: 'name@company.com',
                pt: 'nome@empresa.com'
              }
            },
            dragAndDrop: {
              es: 'Arrastra aquí el archivo Excel',
              en: 'Drag the Excel file here',
              pt: 'Arraste o arquivo Excel aqui'
            },
            buttons: {
              download: {
                es: 'Bajar template',
                en: 'Download template',
                pt: 'Descarregar template'
              },
              downloadSchedule: {
                es: 'Bajar template con envío',
                en: 'Download template with delivery',
                pt: 'Baixe o template com a entrega'
              }
            }
          }
        }
      },
      messages: {
        error: {
          emptyEmail: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es:
                'No es posible enviar una encuesta sin remitente, asunto o contenido de correo.',
              en:
                'It is not possible to send a survey without sender, subject or email body.',
              pt:
                'Não é possível enviar uma pesquisa sem o remetente, sujeito ou corpo de e-mail.'
            }
          }
        }
      }
    },
    whatsapp: {
      settings: {
        title: {
          es: 'Configuración',
          en: 'Settings',
          pt: 'Configuração'
        },
        hsm: {
          title: {
            es: 'Mensaje',
            en: 'Message',
            pt: 'Mensagem'
          },
          subtitle: {
            es: 'Ingresa el template HSM a utilizar, las variables y el idioma',
            en: 'Enter the HSM template to use, the variables and the language',
            pt: 'Digite o template HSM para usar, as variáveis e o idioma'
          },
          form: {
            messageType: {
              es: 'Tipo de mensaje',
              en: 'Message type',
              pt: 'Tipo de mensagem'
            },
            requireVariables: {
              es: '¿Este mensaje requiere variables?',
              en: 'Does this message require variables?',
              pt: 'Essa mensagem requer variáveis?'
            },
            fillOneVariable: {
              es: 'Completar con la primer pregunta de la encuesta',
              en: "Fill variable with survey's first question",
              pt: 'Preencha a variável com a primeira pergunta da pesquisa'
            },
            fillOneVariableTooltip: {
              es: 'Solo si el HSM/Template requiere de una sola variable.',
              en: 'Only if the HSM/Template requires one single variable.',
              pt: 'Só se o HSM/Template precisar de uma única variável.'
            },
            variableInputPlaceholder: {
              es:
                'Ingresa el valor y presiona Enter para agregar la variable número',
              en: 'Write the value and press Enter to add variable number',
              pt:
                'Escreva o valor e pressione Enter para adicionar o número de variável'
            },
            defaultVariables: {
              es: [
                'Hola',
                'En una escala del 1 al 10 ¿Qué tanto recomendarías éste producto a un amigo o colega?'
              ],
              en: [
                'Hello',
                'How likely are you to recommend this product to a friend or colleague?'
              ],
              pt: [
                'Olá.',
                'Qual a probabilidade de recomendar este produto a um amigo ou colega?'
              ]
            },
            nameSpace: {
              label: {
                es: 'namespace',
                en: 'namespace',
                pt: 'namespace'
              },
              placeholder: {
                es: 'namespace',
                en: 'namespace',
                pt: 'namespace'
              }
            },
            elementName: {
              label: {
                es: 'elementName *',
                en: 'elementName *',
                pt: 'elementName *'
              },
              placeholder: {
                es: 'chatclub_southcone_welcome_v1',
                en: 'chatclub_southcone_welcome_v1',
                pt: 'Chatclub_Southcone_welcome_v1'
              }
            },
            campaignAlias: {
              label: {
                es: 'campaignAlias',
                en: 'campaignAlias',
                pt: 'campaignAlias'
              },
              placeholder: {
                es: '',
                en: '',
                pt: ''
              }
            },
            languageCode: {
              label: {
                es: 'languageCode *',
                en: 'languageCode *',
                pt: 'languageCode *'
              },
              placeholder: {
                es: 'lang',
                en: 'lang',
                pt: 'lang'
              }
            },
            namespace: {
              label: {
                es: 'namespace',
                en: 'namespace',
                pt: 'namespace'
              },
              placeholder: {
                es: '',
                en: '',
                pt: ''
              }
            },
            variables: {
              es: 'Variables',
              en: 'Variables',
              pt: 'Variáveis'
            }
          }
        },
        confirmation: {
          title: {
            es: 'Confirmación',
            en: 'Confirmation',
            pt: 'Confirmação'
          },
          subtitle: {
            es:
              'Configura el mensaje para cuando la persona rehusa la encuesta y/o no acepta explícitamente mediante una confirmación',
            en:
              'Configure the message for when the person refuses the survey and/or does not explicitly accept by confirmation',
            pt:
              'Configurar a mensagem para quando a pessoa recusar a pesquisa e/ou não aceitar explicitamente por confirmação'
          },
          form: {
            switch: {
              es: 'Confirmación',
              en: 'Confirmation',
              pt: 'Confirmação'
            },
            firstMessage: {
              label: {
                es: 'Primer mensaje',
                en: 'First message',
                pt: 'Primeira mensagem'
              },
              placeholder: {
                es:
                  '¿Tenés un segundo para responder una encuesta de satisfacción? 😊',
                en: 'Do you have a minute to answer a satisfaction survey? 😊',
                pt:
                  'Tem um minuto para responder a uma pesquisa de satisfação? 😊'
              }
            },
            rejectedMsg: {
              label: {
                es: 'Mensaje para encuesta rechazada',
                en: 'Message for rejected message',
                pt: 'Mensagem para pesquisa rejeitada'
              },
              placeholder: {
                es: 'Ok, quizás la próxima! Gracias',
                en: 'Ok, maybe next time! Thank you',
                pt: 'OK, talvez na próxima vez! Obrigado'
              }
            },
            retryMsg: {
              label: {
                es: 'Mensaje para repregunta',
                en: 'Message for question',
                pt: 'Mensagem para pergunta'
              },
              placeholder: {
                es:
                  'Lo siento, no entendí. ¿Tenés un segundo para responder una encuesta?',
                en:
                  'Sorry I did not understand. Do you have a second to answer a survey?',
                pt:
                  'Desculpe não ter entendido, tem um segundo para responder a uma pesquisa?'
              }
            },
            resendMsg: {
              label: {
                es: 'Mensaje para repregunta',
                en: 'Message for question',
                pt: 'Mensagem para pergunta'
              },
              placeholder: {
                es: 'Oops, no entendí! Responde *SI* o *NO*',
                en: "Oops, I didn't understand! Answer *YES* or *NO*",
                pt: 'Oops, eu não entendi! Responda *SIM* or *NÃO*'
              }
            }
          }
        },
        audience: {
          title: {
            es: 'Audiencia',
            en: 'Audience',
            pt: 'Audiência'
          },
          subtitle: {
            es: 'Carga o ingresa la lista de números que recibirán la encuesta',
            en:
              'Upload or enter the list of numbers that will receive the survey',
            pt: 'Carregue ou digite a lista de números que receberão a pesquisa'
          },
          removeAll: {
            es: 'Eliminar todo',
            en: 'Remove all',
            pt: 'Remova tudo'
          },
          form: {
            numberExists: {
              es: 'El número de teléfono ya está listado',
              en: 'Phone number already in list',
              pt: 'Número de telefone já na lista'
            },
            number: {
              es: 'Número',
              en: 'Number',
              pt: 'Número'
            },
            addNumber: {
              label: {
                es: 'Agregar número',
                en: 'Add number',
                pt: 'Adicione um número'
              },
              placeholder: {
                es: '59899111222',
                en: '59899111222',
                pt: '59899111222'
              }
            },
            dragAndDrop: {
              es: 'Arrastra aquí el archivo Excel',
              en: 'Drag the Excel file here',
              pt: 'Arraste o arquivo Excel aqui'
            },
            expiration: {
              es: 'Expiración de encuesta',
              en: 'Survey expiration',
              pt: 'Expiração da pesquisa'
            },
            expirationPlaceholder: {
              es: 'Seleccione fecha de expiración',
              en: 'Select expiration date',
              pt: 'Selecione a data de validade'
            },
            expirationMessage: {
              es: 'Mensaje a mostrar cuando la encuesta expiró *',
              en: 'Message to show when the survey expired *',
              pt: 'Mensagem para mostrar quando a pesquisa expirou *'
            },
            expirationDefaultMessage: {
              es: 'No puedes responder esta encuesta porque finalizó',
              en: 'You cannot answer this survey because it has ended',
              pt: 'Você não pode responder a esta pesquisa porque ela terminou'
            },
            buttons: {
              download: {
                es: 'Bajar template',
                en: 'Download template',
                pt: 'Descarregar template'
              },
              downloadSchedule: {
                es: 'Bajar template con envío',
                en: 'Download template with delivery',
                pt: 'Baixe o template com a entrega'
              }
            }
          }
        },
        scheduleConfirm: {
          title: {
            es: 'Atención',
            en: 'Warning',
            pt: 'Aviso'
          },
          text: {
            es: 'Desea programar el envío de la encuesta para el ',
            en: 'Do you want to schedule the survey delivery for ',
            pt: 'Você quer agendar a entrega da pesquisa para '
          }
        },
        buttons: {
          send: {
            es: 'Finalizar y enviar',
            en: 'Finish and send',
            pt: 'Terminar e enviar'
          },
          scheduledSend: {
            title: {
              es: 'Programar envío',
              en: 'Schedule delivery',
              pt: 'Entrega do horário'
            },
            datetime: {
              es: 'Fecha y hora',
              en: 'Datetime',
              pt: 'Data e hora'
            }
          }
        }
      },
      messages: {
        confirm: {
          title: {
            es: 'Confirmación',
            en: 'Confirmation',
            pt: 'Confirmação'
          },
          survey: {
            es: 'La encuesta',
            en: 'Survey',
            pt: 'Pesquisa'
          },
          bot: {
            es: 'será enviada mediante el bot',
            en: 'will be sent by bot',
            pt: 'Será enviado por bot'
          },
          channel: {
            es: 'por el canal',
            en: 'via channel',
            pt: 'via canal'
          },
          audience: {
            es: 'a la audiencia',
            en: 'to audience',
            pt: 'para a audiência'
          },
          buttons: {
            accept: {
              es: 'Sí, enviar',
              en: 'Yes, send',
              pt: 'Sim, enviar'
            },
            cancel: {
              es: 'Cancelar',
              en: 'Cancel',
              pt: 'Cancelar'
            }
          }
        },
        success: {
          title: {
            es: 'Éxito!',
            en: 'Success!',
            pt: 'Sucesso!'
          },
          text: {
            es: 'La encuesta fue enviada correctamente',
            en: 'Survey sent successfully',
            pt: 'Pesquisa enviada com sucesso'
          }
        },
        error: {
          emptyAudience: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es: 'No es posible enviar una encuesta con audiencia vacía',
              en: 'It is not possible to send a survey with an empty audience',
              pt: 'Não é possível enviar uma pesquisa com uma audiência vazia'
            }
          },
          missingFields: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es: 'Debes completar los campos requeridos',
              en: 'You must complete the required fields',
              pt: 'Você deve completar os campos necessários'
            }
          },
          missingConfirmationFields: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es: 'Debes completar los campos de confirmación requeridos',
              en: 'You must complete the required confirmation fields',
              pt: 'Você deve completar os campos de confirmação necessários'
            }
          },
          missingAudience: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es:
                'La audiencia no contiene elementos, asegúrate de que el archivo adjuntado corresponda al formato esperado',
              en:
                'The audience does not contain elements, make sure that the attached file corresponds to the expected format',
              pt:
                'A audiência não contém elementos, certifique-se de que o arquivo anexo corresponde ao formato esperado'
            }
          },
          missingExpirationMessage: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es:
                  'Debes incluir el mensaje a mostrar cuando la encuesta expire',
              en:
                  'You must include the message to show when the survey expired',
              pt:
                  'Você deve incluir a mensagem para mostrar quando a pesquisa expirou'
            }
          },
          invalidEmail: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              list: {
                es: 'Existen emails inválidos en la lista',
                en: 'There are invalid emails in the list',
                pt: 'Há e-mails inválidos na lista'
              },
              and: {
                es: 'y',
                en: 'and',
                pt: 'e'
              },
              more: {
                es: 'más...',
                en: 'more...',
                pt: 'mais...'
              }
            }
          },
          invalidDateAfter: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              list: {
                es: 'Las fechas no pueden ser del pasado',
                en: 'Dates cannot be in the past',
                pt: 'As datas não podem estar no passado'
              },
              and: {
                es: 'y',
                en: 'and',
                pt: 'e'
              },
              more: {
                es: 'más...',
                en: 'more...',
                pt: 'mais...'
              }
            }
          },
          invalidDatesSchedule: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              list: {
                es: 'Existen fechas de inicio inválidas en la lista',
                en: 'There are invalid schedule dates in the list',
                pt: 'Há datas inválidas na lista'
              },
              and: {
                es: 'y',
                en: 'and',
                pt: 'e'
              },
              more: {
                es: 'más...',
                en: 'more...',
                pt: 'mais...'
              }
            }
          },
          invalidDatesExpiration: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              list: {
                es: 'Existen fechas de expiración inválidas en la lista',
                en: 'There are invalid expiration dates in the list',
                pt: 'Há datas de validade inválidas na lista'
              },
              and: {
                es: 'y',
                en: 'and',
                pt: 'e'
              },
              more: {
                es: 'más...',
                en: 'more...',
                pt: 'mais...'
              }
            }
          },
          invalidScheduleExpiration: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              list: {
                es:
                  'Existen fechas de inicio / expiración inválidas en la lista, la expiración ser al menos 10 minutos después de la fecha de inicio',
                en:
                  'There are invalid schedule / expiration dates in the list, expiration should be at least 10 minutes after the schedule date',
                pt:
                  'Existem datas de início / expiração inválidas na lista, a expiração deve ser pelo menos 10 minutos após a data de início'
              },
              and: {
                es: 'y',
                en: 'and',
                pt: 'e'
              },
              more: {
                es: 'más...',
                en: 'more...',
                pt: 'mais...'
              }
            }
          },
          dragAndDrop: {
            title: {
              es: 'Oops!',
              en: 'Oops!',
              pt: 'Oops!'
            },
            text: {
              es:
                'Parece que ocurrió un error, asegúrate de que el archivo adjuntado corresponda al formato esperado',
              en:
                'It seems that an error occurred, make sure that the attached file corresponds to the expected format',
              pt:
                'Parece que um erro ocorreu, certifique-se de que o arquivo anexo corresponde ao formato esperado'
            }
          }
        }
      }
    },
    messages: {
      error: {
        surveySettings: {
          es: 'Error al recuperar configuración de encuestas.',
          en: 'An error has ocurred while getting survey settings.',
          pt: 'Ocorreu um erro ao obter as configurações da pesquisa.'
        }
      }
    }
  },
  scheduledSend: {
    title: {
      es: 'Programar envío',
      en: 'Schedule delivery',
      pt: 'Agendar envio'
    },
    subtitle: {
      es: 'Seleccione fecha y hora para enviar la encuesta.',
      en: 'Select date and time to send the survey.',
      pt: 'Selecione a data e hora para enviar a pesquisa.'
    }
  },
  metrics: {
    title: {
      es: 'Métricas de encuestas',
      en: 'Survey metrics',
      pt: 'Métricas de pesquisa'
    }
  },
  tooManyRecipients: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!'
    },
    text: {
      es:
        'La audiencia es demasiado grande, excede el tamaño de XXX destinatarios.',
      en: 'Audience is too large, exceeds XXX recipients.',
      pt: 'O público é muito grande, supera os XXX destinatários.'
    }
  },
  tooManyBytes: {
    title: {
      es: 'Oops!',
      en: 'Oops!',
      pt: 'Oops!'
    },
    text: {
      es: 'La audiencia es demasiado grande, excede el tamaño de XXX bytes.',
      en: 'Audience is too large, exceeds XXX bytes.',
      pt: 'O público é muito grande, excede XXX bytes.'
    }
  },
  htmlBuilder: {
    default: {
      header: {
        text: {
          es: 'Hola!',
          en: 'Hello!',
          pt: 'Olá!'
        }
      },
      button: {
        text: {
          es: 'Empezar encuesta',
          en: 'Start survey',
          pt: 'Iniciar a pesquisa'
        }
      },
      body: {
        title: {
          es: 'Encuesta',
          en: 'Survey',
          pt: 'Pesquisa'
        },
        text: {
          es:
            'Por favor, haz click {{aquí}} o en el botón de abajo para realizar la encuesta',
          en: 'Please click {{here}} or on the button below to take the survey',
          pt:
            'Por favor clique {{aqui}} ou no botão abaixo para fazer a pesquisa'
        }
      },
      footer: {
        title: {
          es: 'Muchas gracias!',
          en: 'Thank you!',
          pt: 'Obrigado!'
        },
        text: {
          es: 'Tu opinión es muy importante para nosotros',
          en: 'Your opinion is important for us',
          pt: 'Sua opinião é importante para nós'
        }
      }
    },
    svgAlertTitle: {
      es: 'Atención',
      en: 'Warning',
      pt: 'Aviso'
    },
    svgAlert: {
      es:
        'Imágenes del tipo .SVG pueden no ser compatibles con todos los correos',
      en: 'Images of the type .SVG may not be compatible with all emails',
      pt: 'Imagens do tipo .SVG podem não ser compatíveis com todos os e-mails'
    },
    importSettings: {
      success: {
        es: 'Importación exitosa',
        en: 'Successfull import',
        pt: 'Importação com sucesso'
      }
    },
    areYouSureSave: {
      es:
        '¿Está seguro de que desea guardar los cambios? Está acción es irreversible',
      en:
        'Are you sure you want to save these changes? This action is irreversible ',
      pt: 'Tem certeza que quer salvar essas mudanças? Esta ação é irreversível'
    },
    nextLinkHtmlTemplate: {
      es: 'del siguiente campo',
      en: 'of the next text field',
      pt: 'do próximo campo de texto'
    },
    imageSize: {
      es: 'Altura de imagen en pixeles',
      en: 'Image height in pixels',
      pt: 'Altura da imagem em pixels'
    },
    errorHtmlTemplate: {
      es: 'El template debe contener el enlace a la encuesta %s para funcionar',
      en: 'Template must contain the survey link %s to work',
      pt: 'Template deve conter o link de pesquisa %s para trabalhar'
    },
    settings: {
      export: {
        es: 'Exportar Ajustes',
        en: 'Export Settings',
        pt: 'Exportar Configuração'
      },
      import: {
        es: 'Importar Ajustes',
        en: 'Import Settings',
        pt: 'Importar Configuração'
      }
    },
    import: {
      es: 'Importar HTML',
      en: 'Import HTML',
      pt: 'Importar HTML'
    },
    export: {
      es: 'Exportar HTML',
      en: 'Export HTML',
      pt: 'Exportar HTML'
    },
    reset: {
      es: 'Reiniciar opciones',
      en: 'Reset to default',
      pt: 'Restaurar para o padrão'
    },
    logo: {
      text: {
        es: 'Imagen email',
        en: 'Email picture',
        pt: 'Imagem de e-mail'
      },
      remove: {
        es: 'Remover imagen email',
        en: 'Remove email picture',
        pt: 'Remover a imagem do e-mail'
      }
    },
    button: {
      text: {
        es: 'Texto botón',
        en: 'Button text',
        pt: 'Texto do botão'
      },
      color: {
        text: {
          es: 'Color Texto Botón',
          en: 'Button Text Color',
          pt: 'Cor do Texto do Botão'
        },
        background: {
          es: 'Color Fondo Botón',
          en: 'Button Background Color',
          pt: 'Cor do fundo do botão'
        }
      }
    },
    header: {
      text: {
        es: 'Texto encabezado',
        en: 'Header text',
        pt: 'Texto do cabeçalho'
      },
      color: {
        text: {
          es: 'Color Texto Encabezado',
          en: 'Header Text Color',
          pt: 'Cor do texto do cabeçalho'
        },
        background: {
          es: 'Color Fondo Encabezado',
          en: 'Header Background Color',
          pt: 'Cor de fundo do cabeçalho'
        }
      }
    },
    footer: {
      title: {
        es: 'Título Pie de página',
        en: 'Footer text title',
        pt: 'Título do texto do pé de página'
      },
      text: {
        es: 'Texto Pie de página',
        en: 'Footer text',
        pt: 'Texto do pé de página'
      },
      color: {
        background: {
          es: 'Color Fondo Pie de página',
          en: 'Footer Background Color',
          pt: 'Cor do fundo do pé'
        },
        title: {
          es: 'Color Título Pie de página',
          en: 'Footer Title Color',
          pt: 'Footer Title Color'
        },
        text: {
          es: 'Color Texto Pie de página',
          en: 'Footer Text Color',
          pt: 'Cor do texto do pé de página'
        }
      }
    },
    body: {
      title: {
        es: 'Título Mensaje',
        en: 'Body text title',
        pt: 'Título da Mensagem'
      },
      text: {
        es: 'Texto Mensaje',
        en: 'Body text',
        pt: 'Texto da Mensagem'
      },
      color: {
        title: {
          es: 'Color Título Mensaje',
          en: 'Body Title Color',
          pt: 'Cor do Título da Mensagem'
        },
        text: {
          es: 'Color Texto Mensaje',
          en: 'Body Text Color',
          pt: 'Cor do Corpo do Texto'
        }
      }
    },
    popup: {
      button: {
        es: 'Editar template de email',
        en: 'Edit email template',
        pt: 'Editar template de e-mail'
      },
      title: {
        es: 'Editor de template de email',
        en: 'Email template editor',
        pt: 'Editor de template de e-mail'
      }
    }
  }
}
