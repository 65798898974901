/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import { themeConfig } from '@/../themeConfig.js'
import languages from '@/languages/languages'
import { CHAT_USER_STATUS } from '@/constants/constants'
import {
  appActiveUserFactory,
  companyLogoFactory,
  themeFactory,
  themeColorsFactory,
  themePrimaryColorFactory,
  languagesFactory,
  pluginsFactory,
  chatGeneralSettingsFactory
} from './factories'

const session = localStorage.session && JSON.parse(localStorage.session)

const state = {
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: 'default',
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  navbarSearchAndPinList: navbarSearchAndPinList,
  AppActiveUser: appActiveUserFactory(),
  companyLogo: companyLogoFactory(),
  theme: themeFactory(),
  themeColors: themeColorsFactory(),
  themePrimaryColor: themePrimaryColorFactory(),
  chatTransitionSettingsScope: 'company',
  sendSurveySettingsScope: 'company',
  sendSurveySettings: {
    surveyEnabled: false,
    survey: '',
    surveyConfirmation: {},
    surveyHsm: {}
  },
  chatTransitionSettings: {
    finish: {},
    transfer: {}
  },
  chatGeneralSettings: chatGeneralSettingsFactory(),
  videoCall: {
    active: false,
    config: {
      url: ''
    }
  },

  security: {
    ipFiltering: false
  },
  ipRanges: [],

  config: {
    konectaWidget: '',
    konectaWidgetStaging: '',
    konecta: '',
    chat: '',
    videocall: ''
  },

  session: {},

  bot: {
    name: '',
    platform: ''
  },

  attachmentFile: {},

  lang: languages,
  languageSelected: session && session.lang ? session.lang : 'en',
  languages: languagesFactory(),
  newBotLanguage: session && session.lang ? session.lang : 'en',

  activeChatUser: null,
  takeoverPending: null,
  newChatUserName: null,
  activeChatMessages: [], // mensajes del chat activo
  suggestedChatMessage: null,
  sentSuggestion: false,
  chatUserStatus: CHAT_USER_STATUS.offline,
  chatHasWhatsappRestriction: false,

  isDeleteSurveyPromptOpen: false,

  maximizeChatContainer: false, // maximizar ventana de chat
  maximizeLogsStreamContainer: false, // maximizar ventana de logs

  joinUpdate: '',
  contains: '',
  companyId: '',
  companyAuthKey: '',

  // plugins

  plugins: pluginsFactory(),

  botsToSubscribe: [],
  queuesToSubscribe:[],
  chatsToSubscribe: [],
  socket: null,

  importBot: false,
  changePassword: false,
  useCyberbank: false,
  useAnswers: false,
  useStaffManagement: false
}

export default state
