import general from './general'
import login from './login'
import attentionRequests from './attentionRequests'
import botMaker from './botMaker'
import metrics from './metrics'
import metricSessions from './metricSessions'
import metricAgents from './metricAgents'
import plugins from './plugins/plugins'
import errors from './errors'
import surveyMaker from './surveyMaker'
import surveyData from './surveyData'
import surveyIntegrations from './surveyIntegrations'
import users from './users'
import sidebar from './sidebar'
import resetPassword from './resetPassword'
import botTest from './botTest'
import help from './help'
import apiCalls from './apiCalls'
import entities from './entities'
import variables from './variables'
import themes from './themes'
import nlu from './nlu'
import contextVariables from './contextVariables'
import changeTimeDurationDropdown from './changeTimeDurationDropdown'
import footer from './footer'
import chats from './chats'
import navbar from './navbar'
import treeView from './treeView'
import forking from './forking'
import intentions from './intentions'
import conditions from './conditions'
import newChat from './newChat'
import audiences from './audiences'
import botLogsStream from './botLogsStream'
import changePassword from './changePassword'
import helpTooltips from './helpTooltips'
import chatTransitionSettings from './chatTransitonSettings'
import videoCall from './videoCall'
import clustering from './clustering'
import sessionsFlow from './sessionsFlow'
import surveyCharts from './surveyCharts'
import filters from './filters'
import setPassword from './setPassword'
import accountSetup from './accountSetup'
import chargifySignup from './chargifySignup'
import audit from './audit'
import companies from './companies'
import security from './security'
import staffRoles from './staffRoles'
import queues from './queues'

export default {
  general,
  login,
  attentionRequests,
  botMaker,
  botTest,
  metrics,
  metricSessions,
  metricAgents,
  plugins,
  errors,
  surveyMaker,
  users,
  sidebar,
  resetPassword,
  help,
  apiCalls,
  entities,
  variables,
  themes,
  nlu,
  contextVariables,
  changeTimeDurationDropdown,
  footer,
  chats,
  surveyData,
  surveyIntegrations,
  navbar,
  treeView,
  forking,
  intentions,
  conditions,
  newChat,
  audiences,
  botLogsStream,
  changePassword,
  helpTooltips,
  chatTransitionSettings,
  videoCall,
  clustering,
  sessionsFlow,
  surveyCharts,
  filters,
  setPassword,
  accountSetup,
  chargifySignup,
  audit,
  companies,
  security,
  staffRoles,
  queues
}
