// main.js

/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import App from './App.vue'

// Vue logger
import VueLogger from 'vuejs-logger'
import './utils'
import './utils/loadGTM'

const isProduction = process.env.NODE_ENV === 'production'

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, loggerOptions)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax

// Lodash
import VueLodash from 'vue-lodash'
const options = {
  name: 'lodash'
}
Vue.use(VueLodash, options)

// Load Sentry
// import './sentryConfig.js'

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Vuesax Admin Filters
import './filters/filters'

// Directives
import './directives/directives'

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism.css'

// Sockets

// Push notifications

import VueNativeNotification from 'vue-native-notification'

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

import VueDOMPurifyHTML from 'vue-dompurify-html';

Vue.use(VueDOMPurifyHTML);

// Feather font icon
require('./assets/css/iconfont.css')

// CSS animations
require('./assets/css/animations.css')

// MomentJS
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

import { notificationPlugin } from './plugins/notify'
Vue.use(notificationPlugin)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

Vue.use(Vuesax, {
  theme: store.state.themeColors
})

import TextHighlight from 'vue-text-highlight'
Vue.component('text-highlight', TextHighlight)

import VxSlider from './components/vx-slider/VxSlider.vue'
Vue.component('vx-slider', VxSlider)

import VueHtml2Canvas from 'vue-html2canvas'
Vue.use(VueHtml2Canvas)

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VueGtag from 'vue-gtag'
if (window.config && window.config.GOOGLE_ANALYTICS) {
  Vue.use(VueGtag, {
    config: { id: window.config.GOOGLE_ANALYTICS }
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
