export default {
  header: {
    attentionRequests: {
      es: 'Pedidos de atención',
      en: 'Pending chats',
      pt: 'Chats pendentes'
    },
    allChats: {
      es: 'Todos los chats',
      en: 'All chats',
      pt: 'Todos os chats'
    },
    queues: {
      es: 'Colas de atencion',
      en: 'Attentions queues',
      pt: 'Colas de atencion'
    },
    botMaker: {
      es: 'Bot maker',
      en: 'Bot maker',
      pt: 'Bot maker'
    },
    metrics: {
      es: 'Métricas',
      en: 'Metrics',
      pt: 'Métricas'
    },
    surveys: {
      es: 'Encuestas',
      en: 'Surveys',
      pt: 'Pesquisas'
    },
    results: {
      es: 'Resultados de Encuestas',
      en: 'Surveys Results',
      pt: 'Resultados das pesquisas'
    },
    users: {
      es: 'Usuarios',
      en: 'Users',
      pt: 'Usuários'
    },
    userStatus: {
      set: {
        es: 'Cambiar',
        en: 'Change',
        pt: 'Mudar'
      },
      youAre: {
        es: 'Estás ',
        en: "You're set to ",
        pt: 'Você está '
      },
      offline: {
        es: 'ausente',
        en: 'away',
        pt: 'ausente'
      },
      online: {
        es: 'disponible',
        en: 'online',
        pt: 'disponivel'
      }
    },
    logout: {
      es: 'Cerrar sesión',
      en: 'Logout',
      pt: 'Sair'
    }
  }
}
