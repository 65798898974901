/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './state.js'
import ConfigService from '../services/config.service'
import AttentionRequestsService from '../services/attentionRequests.service'
import {
  chatTransitionSettingsFactory,
  sendSurveySettingsFactory
} from './factories'

const actions = {
  updateAndSaveSession({ commit }, session) {
    localStorage.session = JSON.stringify(session)
    localStorage.sessionStatus = true
    state.session = null
    commit('UPDATE_SESSION', session)
  },

  updateSelectedBot({ commit }, bot) {
    commit('SELECT_BOT', bot)
  },

  updateContains({ commit }, contains) {
    commit('UPDATE_CONTAINS', contains)
  },

  verifyMessage(context, message) {
    let json
    try {
      json = JSON.parse(message)
    } catch (ex) {
      // NADA
    }
    if (
      json &&
      json.type &&
      json.type === 'LOCATION' &&
      json.location &&
      json.location.geoPoint
    ) {
      return {
        type: 'location',
        url: `https://www.google.com/maps/place/${json.location.geoPoint}`
      }
    }

    if (!message) {
      return {
        type: 'text',
        text: message
      }
    }
    const backendBaseUrl = state.config.konecta
    if (/^\/bucket/.test(message)) {
      let type
      if (/image/.test(message)) {
        type = 'image'
      } else if (/video/.test(message)) {
        type = 'video'
      } else if (/audio/.test(message)) {
        type = 'audio'
      } else {
        type = 'file'
      }

      return {
        type: type,
        url: `${backendBaseUrl}${message}`
      }
    } else if (/\[\/bucket/.test(message)) {
      message = message.replace('[/bucket', `[${backendBaseUrl}/bucket`)
    }

    if (
      message.match(
        /^(https:\/\/([^\.]*konecta[^\.]*)\.s3\.amazonaws\.com\/(image)-[0-9a-zA-Z]{24}|https:\/\/chatclub-cdn\..+jpeg|https:\/\/scontent.*\.(jpg|jpeg|gif|png).*|https:\/\/cdn\.fbsbx.*\.(jpg|jpeg|gif|png).*|https:\/\/.*fbcdn.*\.(jpg|jpeg|gif|png).*)$/
      )
    ) {
      return {
        type: 'image',
        url: message
      }
    } else if (
      message.match(
        /^(https:\/\/([^\.]*konecta[^\.]*)\.s3\.amazonaws\.com\/(video)-[0-9a-zA-Z]{24}|https:\/\/chatclub-cdn\..+mp4|https:\/\/scontent.*\.mp4.*|https:\/\/cdn\.fbsbx.*\.mp4.*|https:\/\/.*fbcdn.*\.mp4.*)$/
      )
    ) {
      return {
        type: 'video',
        url: message
      }
    } else if (
      message.match(
        /^https:\/\/([^\.]*konecta[^\.]*)\.s3\.amazonaws\.com\/(audio)-[0-9a-zA-Z]{24}$/
      )
    ) {
      return {
        type: 'audio',
        url: message
      }
    } else if (
      message.match(
        /^(https:\/\/([^\.]*konecta[^\.]*)\.s3\.amazonaws\.com\/(file)-[0-9a-zA-Z]{24}|https:\/\/chatclub-cdn\..+pdf|https:\/\/scontent.*\.pdf.*|https:\/\/cdn\.fbsbx.*\.pdf.*|https:\/\/.*fbcdn.*\.pdf.*)$/
      )
    ) {
      return {
        type: 'file',
        url: message
      }
    } else if (message.match(/^.* \[https:\/\/.*\.wavy\.global.*\]$/)) {
      // Archivo desde WAVY
      if (message.match(/\.(jpg|jpeg|gif|png)/)) {
        return {
          type: 'image',
          name: message.split('[')[0],
          url: message.split('[')[1].split(']')[0]
        }
      }
      if (message.match(/\.(mp4)/)) {
        return {
          type: 'video',
          name: message.split('[')[0],
          url: message.split('[')[1].split(']')[0]
        }
      }
      return {
        type: 'file',
        name: message.split('[')[0],
        url: message.split('[')[1].split(']')[0]
      }
    } else {
      return {
        type: 'text',
        text: message
      }
    }
  },

  // plugins

  // KBASE

  updatePluginActive({ commit }, plugin) {
    var plugins = state.plugins
    plugins[plugin.name].active = status
    commit('UPDATE_PLUGINS', plugins)
  },

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width)
  },
  updateI18nLocale({ commit }, locale) {
    commit('UPDATE_I18N_LOCALE', locale)
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },
  updateBotsToSubscribe({ commit }, val) {
    commit('UPDATE_BOTS_TO_SUBSCRIBE', val)
  },
  updateQueuesToSubscribe({ commit }, val) {
    commit('UPDATE_QUEUES_TO_SUBSCRIBE', val)
  },
  updateChatstoSubscribe({ commit }, val) {
    commit('UPDATE_CHATS_TO_SUBSCRIBE', val)
  },
  updateSocket({ commit }, val) {
    commit('UPDATE_SOCKET', val)
  },
  setCompanyLogo({ commit }, companyLogo) {
    commit('SET_COMPANY_LOGO', companyLogo)
  },
  async saveCompanyTheme(context, theme) {
    await ConfigService.saveCompanyTheme(theme)
  },
  async saveCompanyPasswordValidity(context, passwordValidity) {
    await ConfigService.saveCompanyPasswordValidity(passwordValidity)
  },
  async saveCompanyChatTransitionSettings(context, chatTransitionSettings) {
    await ConfigService.saveChatTransitionSettings(chatTransitionSettings)
  },
  async saveCompanySurveySettings(context, companySurveySettings) {
    await ConfigService.saveCompanySurveySettings(companySurveySettings)
  },
  async saveCompanySecuritySettings(context, companySecuritySettings) {
    await ConfigService.saveCompanySecuritySettings(companySecuritySettings)
  },
  async saveCompanyChatTransitionSettingsScope(
    context,
    chatTransitionSettingsScope
  ) {
    await ConfigService.saveChatTransitionSettingsScope(
      chatTransitionSettingsScope
    )
  },
  async saveCompanyChatGeneralSettings({ commit }, chatGeneralSettings) {
    const response = await ConfigService.saveChatGeneralSettings(
      chatGeneralSettings
    )
    if (response && response.data && response.data.priorityQueueSettings) {
      commit(
        'UPDATE_CHAT_GENERAL_SETTINGS',
        response.data.priorityQueueSettings
      )
    }
  },
  async saveCompanyLanguage(context, language) {
    await ConfigService.saveCompanyLanguage(language)
  },
  updateLoginMethod({ commit }, val) {
    commit('UPDATE_LOGIN_METHOD', val)
  },
  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  async getCompanySettings({ commit }) {
    try {
      const response = await ConfigService.getCompanySettings()
      if (response && response.data) {
        commit('UPDATE_COMPANY_ID', response.data._id)
        commit('UPDATE_COMPANY_AUTH_KEY', response.data.authKey)
        commit('UPDATE_IMPORT_BOT', response.data.importBot)
        // update theme
        if (response.data.theme && response.data.theme.colors) {
          commit('UPDATE_THEME_COLORS', response.data.theme.colors)
        } else {
          commit('UPDATE_THEME_COLORS', state.themeColors)
        }
        if (response.data.theme && response.data.theme.companyLogo) {
          commit('SET_COMPANY_LOGO', response.data.theme.companyLogo)
        } else {
          commit('SET_COMPANY_LOGO', null)
        }
        // update language
        if (response.data.lang) commit('UPDATE_LANGUAGE', response.data.lang)

        commit('SET_COMPANY_CYBERBANK', response.data.useCyberbank == true)
        commit('SET_COMPANY_ANSWERS', response.data.useAnswers == true)

        if (response.data.useStaffManagement)
          commit(
            'SET_COMPANY_STAFF_MANAGEMENT',
            response.data.useStaffManagement
          )

        let passwordValidity
        if (response.data.passwordValidity) {
          passwordValidity = response.data.passwordValidity
        } else {
          passwordValidity = {
            time: 0,
            roles: []
          }
        }
        commit('UPDATE_PASSWORD_VALIDITY', passwordValidity)

        let chatTransitionSettingsScope
        if (response.data.chatTransitionSettingsScope) {
          chatTransitionSettingsScope =
            response.data.chatTransitionSettingsScope
        } else {
          chatTransitionSettingsScope = 'company'
        }
        commit(
          'UPDATE_CHAT_TRANSITION_SETTINGS_SCOPE',
          chatTransitionSettingsScope
        )

        commit(
          'UPDATE_SECURITY',
          response.data.security || { ipFiltering: false }
        )

        let chatTransitionSettings
        if (response.data.chatTransitionSettings) {
          chatTransitionSettings = response.data.chatTransitionSettings
        } else {
          chatTransitionSettings = chatTransitionSettingsFactory()
        }
        commit('UPDATE_CHAT_TRANSITION_SETTINGS', chatTransitionSettings)

        let sendSurveySettingsScope
        if (response.data.sendSurveySettingsScope) {
          sendSurveySettingsScope = response.data.sendSurveySettingsScope
        } else {
          sendSurveySettingsScope = 'company'
        }
        commit('UPDATE_COMPANY_SURVEY_SETTINGS_SCOPE', sendSurveySettingsScope)

        let sendSurveySettings
        if (response.data.sendSurveySettings) {
          sendSurveySettings = response.data.sendSurveySettings
        } else {
          sendSurveySettings = sendSurveySettingsFactory()
        }
        commit('UPDATE_COMPANY_SURVEY_SETTINGS', sendSurveySettings)

        if (response.data.priorityQueueSettings) {
          commit(
            'UPDATE_CHAT_GENERAL_SETTINGS',
            response.data.priorityQueueSettings
          )
        }

        let videoCall
        if (response.data.videoCall) {
          videoCall = response.data.videoCall
        } else {
          videoCall = {
            active: false,
            config: {
              url: ''
            }
          }
        }
        commit('UPDATE_VIDEO_CALL', videoCall)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async sendTextMessage({ commit }, message) {
    try {
      await AttentionRequestsService.respondTakeover(message)
      commit('UPDATE_SENT_SUGGESTION', true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default actions
