const getters = {
  apiCalls: state => {
    return state.apiCalls
  },
  entities: state => {
    return state.entities
  },
  variables: state => {
    return state.variables
  },
  services: state => {
    return state.services
  },
  getEntityValues: state => entityId => {
    const entity = state.entities.find(e => e.entityId === entityId)
    return entity.values.map(e => e.value)
  },
  getVariablesValues: state => variableId => {
    const variable = state.variables.find(v => v.id === variableId)
    return variable.values.map(v => v.value)
  },
  bot: state => {
    return state.bot
  },
  botId: state => {
    return state.botId
  },
  botMakerMessages: state => {
    return state.botMakerMessages
  },
  userMessageInEdit: state => {
    return state.userMessageInEdit
  },
  botMessageInEdit: state => {
    return state.botMessageInEdit
  },
  hasChildren: state => {
    let dialogId
    if (state.userMessageInEdit && state.userMessageInEdit.isEditing) {
      dialogId = state.userMessageInEdit.dialogId
    } else if (state.botMessageInEdit) {
      dialogId = state.botMessageInEdit.dialogId
    }
    if (dialogId) {
      return (
        state.dialogsMap &&
        state.dialogsMap[dialogId] &&
        state.dialogsMap[dialogId].children
      )
    }
  },
  animateButtons: state => {
    return state.animateButtons
  },
  changedMessageInEdit: state => {
    return state.changedMessageInEdit
  },
  prevAlternativeMessages: state => {
    return state.prevAlternativeMessages
  },
  nextAlternativeMessages: state => {
    return state.nextAlternativeMessages
  },
  intentions: state => {
    return state.intentions
  },
  existingIntentionName: state => intentionName => {
    return state.intentions.some(i => i.name === intentionName)
  },
  selectedIntention: state => {
    return state.selectedIntention
  },
  numberOfIntentions: state => {
    let messages = state.messages

    if (messages.length > 0) {
      return state.messages.filter(m => {
        return m.isUserMessage()
      }).length
    }

    return 0
  },
  activeUserTab: state => {
    return state.activeUserTab
  },
  activeBotTab: state => {
    return state.activeBotTab
  },
  dialogsMap: state => {
    return state.dialogsMap
  },

  numberOfContextVariables: state => dialogId => {
    if (!state.dialogsMap[dialogId] || !state.dialogsMap[dialogId].actions)
      return 0
    return state.dialogsMap[dialogId].actions.filter(
      element => element.key && element.key === 'save variable'
    ).length
  },

  numberOfIntegrations: state => dialogId => {
    if (!state.dialogsMap[dialogId] || !state.dialogsMap[dialogId].apiCall)
      return 0
    return state.dialogsMap[dialogId].apiCall[0].length
  },
  numberOfSurveys: state => dialogId => {
    if (
      state.dialogsMap[dialogId] &&
      state.dialogsMap[dialogId].sendSurveySettings &&
      state.dialogsMap[dialogId].sendSurveySettings.surveyEnabled
    ) {
      return 1
    }
    return 0
  },
  integrationsByDialogId: state => dialogId => {
    // if (state.dialogsMap[dialogId] || state.dialogsMap[dialogId].apiCall)
    //   return state.dialogsMap[dialogId].apiCall[0]
    // return null
    if (state.dialogsMap[dialogId] && state.dialogsMap[dialogId].apiCall) {
      return state.dialogsMap[dialogId].apiCall
    }
    return null
  },
  getMessageById: state => messageId => {
    return state.messages.find(m => m.id === messageId)
  },
  getApiCallById: state => apiId => {
    return state.apiCalls.find(a => a._id === apiId)
  },
  dialogs: state => {
    return state.dialogs
  },
  dialogMsgId: state => {
    return state.dialogMsgId
  },
  dialogHasJump: state => dialogId => {
    return state.dialogsMap[dialogId] && state.dialogsMap[dialogId].jump
  },
  dialogHasSurvey: state => dialogId => {
    return (
      state.dialogsMap[dialogId] &&
      state.dialogsMap[dialogId].sendSurveySettings &&
      state.dialogsMap[dialogId].sendSurveySettings.surveyEnabled
    )
  },
  currentDialogHasJumps: state => {
    return state.currentDialogHasJumps
  },
  currentDialogHasSurveys: state => {
    return state.currentDialogHasSurveys
  },
  currentDialogHasValidation: state => {
    return state.currentDialogHasValidation
  },
  lastAlternativeDialogSavedId: state => {
    return state.lastAlternativeDialogSavedId
  },
  conditionsGroups: state => {
    return state.conditionsGroups
  },
  conditionsFilters: state => {
    return state.conditionsFilters
  },
  conditionName: state => {
    return state.conditionName
  },
  secondLevelAttention: state => {
    return state.secondLevelAttention
  },
  secondLevelAttentionHasChanged: state => {
    return state.secondLevelAttentionHasChanged
  },
  replyOutsideBusinessHours: state => {
    return state.replyOutsideBusinessHours
  },
  conditionTags: state => {
    return state.conditionTags
  },
  selectedVariable: state => {
    return state.selectedVariable
  },
  // newVariableName: state => {
  //   return state.newVariableName
  // },
  // existingVariableName: state => variableName => {
  //   return state.variables.some(v => v.label === variableName)
  // },
  // activeVariable: state => {
  //   return state.activeVariable
  // },
  selectedEntity: state => {
    return state.selectedEntity
  },
  newEntityName: state => {
    return state.newEntityName
  },
  existingEntityName: state => entityName => {
    return state.entities.some(i => i.name === entityName)
  },
  activeIntention: state => {
    return state.activeIntention
  },
  activeEntity: state => {
    return state.activeEntity
  },
  // uploadProgress: state => {
  //   return state.uploadProgress
  // },
  activeChannelView: state => {
    return state.activeChannelView
  },
  responseDialogId: state => {
    return state.responseDialogId
  },
  selectedResponse: state => {
    return state.selectedResponse
  },
  responseIndex: state => {
    return state.responseIndex
  },
  sliderChange: state => {
    return state.sliderChange
  },
  botValidationEnabled: state => {
    return state.botValidationEnabled
  },
  botMessageValidation: state => {
    return state.botMessageValidation
  },
  botMessageValidationApplyAll: state => {
    return state.botMessageValidationApplyAll
  },
  responseValidations: state => {
    return state.responseValidations
  },
  validationUpdateMode: state => {
    return state.validationUpdateMode
  },
  intentionsMap: state => {
    return state.intentionsMap
  },
  activeVersionData: state => {
    if (state.bot && state.bot.activeVersion) {
      return {
        versionId: state.bot.activeVersion,
        versionFork: state.bot.fork
      }
    } else {
      return null
    }
  },
  activeVersion: state => {
    return state.bot && state.bot.activeVersion
      ? state.bot.activeVersion
      : undefined
  },
  botActiveVersion: state => {
    return state.botVersions.find(a => a._id == state.bot.activeVersion)
  },
  botVersions: state => {
    return state.botVersions.sort((a, b) => b.active - a.active)
  },
  cyberbankVariables: state => {
    return state.cyberbankVariables
  },
  testGroups: state => {
    return state.testGroups
  },
  selectedTestGroup: state => {
    return state.selectedTestGroup
  },
  selectedTestCase: state => {
    return state.selectedTestCase
  },
  testCases: state => {
    return state.testCases
  },
}

export default getters
