export default {
  title: {
    es: 'Métricas',
    en: 'Metrics',
    pt: 'Métricas'
  },
  dashboard: {
    es: 'Dashboard',
    en: 'Dashboard',
    pt: 'Painel'
  },
  knowledge: {
    es: 'Conocimiento',
    en: 'Knowledge',
    pt: 'Conhecimento'
  },
  showMore: {
    es: 'Mostrar más',
    en: 'Show more',
    pt: 'Mostre mais'
  },
  showLess: {
    es: 'Mostrar menos',
    en: 'Show less',
    pt: 'Mostre menos'
  },
  download: {
    es: 'Descargar',
    en: 'Download',
    pt: 'Baixe'
  },
  hours: {
    es: 'Hora',
    en: 'Hour',
    pt: 'Hora'
  },
  card1: {
    title: {
      es: 'Felicitaciones',
      en: 'Congratulations',
      pt: 'Parabéns'
    },
    message: {
      part1: {
        es: 'Cerraste',
        en: 'You have closed',
        pt: 'Você fechou'
      },
      percentage: {
        es: '57.6%',
        en: '57.6%',
        pt: '57,6%'
      },
      part2: {
        en: 'more support tickets today.',
        es: 'más tickets de soporte hoy.',
        pt: 'Mais ingressos de apoio hoje.'
      }
    }
  },
  card2: {
    number: {
      es: '92.6k',
      en: '92.6k',
      pt: '92.6k'
    },
    message: {
      es: 'Suscriptores Ganados',
      en: 'Subscribers Gained',
      pt: 'Assinantes obtidos'
    }
  },
  card3: {
    number: {
      es: '97.5K',
      en: '97.5K',
      pt: '97.5K'
    },
    message: {
      es: 'Órdenes Recibidas',
      en: 'Orders Received',
      pt: 'Pedidos Recebidos'
    }
  },
  card4: {
    number: {
      es: '2.7k',
      en: '2.7k',
      pt: '2.7k'
    },
    subtitle: {
      es: 'Sesiones promedio',
      en: 'Avg Sessions',
      pt: 'Sessões médias'
    },
    percentage: {
      es: '+5.2%',
      en: '+5.2%',
      pt: '+5.2%'
    },
    percentageColor: {
      es: 'éxito',
      en: 'success',
      pt: 'sucesso'
    },
    vs: {
      es: 'vs últimos 7 días',
      en: 'vs last 7 days',
      pt: 'vs últimos 7 dias'
    },
    button: {
      es: 'Ver Detalles',
      en: 'View Details',
      pt: 'Veja detalhes'
    },
    data1: {
      es: 'Meta: $100000',
      en: 'Goal: $100000',
      pt: 'Meta: $100000'
    },
    data2: {
      es: 'Usuarios: 100K',
      en: 'Users: 100K',
      pt: 'Usuários: 100K'
    },
    data3: {
      es: 'Retención: 90%',
      en: 'Retention: 90%',
      pt: 'Retenção: 90%'
    },
    data4: {
      es: 'Duración: 1 año',
      en: 'Duration: 1yr',
      pt: 'Duração: 1 ano'
    }
  },
  card5: {
    title: {
      es: 'Panel de soporte',
      en: 'Support Tracker',
      pt: 'Painel de suporte'
    },
    data1: {
      number: {
        es: '163',
        en: '163',
        pt: '163'
      },
      title: {
        es: 'Tickets',
        en: 'Tickets',
        pt: 'Tickets'
      }
    },
    data2: {
      number: {
        es: '29',
        en: '29',
        pt: '29'
      },
      title: {
        es: 'Tickets nuevos',
        en: 'New Tickets',
        pt: 'Tickets novos'
      }
    },
    data3: {
      number: {
        es: '63',
        en: '63',
        pt: '63'
      },
      title: {
        es: 'Tickets abiertos',
        en: 'Open Tickets',
        pt: 'Tickets abertos'
      }
    },
    data4: {
      number: {
        es: '1d',
        en: '1d',
        pt: '1d'
      },
      title: {
        es: 'Tiempo de respuesta',
        en: 'Response Time',
        pt: 'Tempo de resposta'
      }
    },
    centerData: {
      es: {
        plotOptions: {
          radialBar: {
            size: 150,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '65%'
            },
            track: {
              background: '#fff',
              strokeWidth: '100%'
            },
            dataLabels: {
              value: {
                offsetY: 30,
                color: '#99a2ac',
                fontSize: '2rem'
              }
            }
          }
        },
        colors: ['#EA5455'],
        fill: {
          type: 'gradient',
          gradient: {
            // enabled: true,
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#7367F0'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          dashArray: 8
        },
        chart: {
          sparkline: {}
        },
        labels: ['Tickets completados']
      },
      en: {
        plotOptions: {
          radialBar: {
            size: 150,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '65%'
            },
            track: {
              background: '#fff',
              strokeWidth: '100%'
            },
            dataLabels: {
              value: {
                offsetY: 30,
                color: '#99a2ac',
                fontSize: '2rem'
              }
            }
          }
        },
        colors: ['#EA5455'],
        fill: {
          type: 'gradient',
          gradient: {
            // enabled: true,
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#7367F0'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          dashArray: 8
        },
        chart: {
          sparkline: {}
        },
        labels: ['Completed Tickets']
      }
    },
    pt: {
      plotOptions: {
        radialBar: {
          size: 150,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '65%'
          },
          track: {
            background: '#fff',
            strokeWidth: '100%'
          },
          dataLabels: {
            value: {
              offsetY: 30,
              color: '#99a2ac',
              fontSize: '2rem'
            }
          }
        }
      },
      colors: ['#EA5455'],
      fill: {
        type: 'gradient',
        gradient: {
          // enabled: true,
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#7367F0'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      chart: {
        sparkline: {}
      },
      labels: ['Tickets concluídos']
    }
  },
  card6: {
    title: {
      es: 'Ordenes de producto',
      en: 'Product Orders',
      pt: 'Pedidos de produtos'
    },
    productOrdersRadialBar: {
      analyticsData: {
        es: [
          { orderType: 'Terminado', counts: 23043, color: 'primary' },
          { orderType: 'Pendiente', counts: 14658, color: 'warning' },
          { orderType: 'Rechazado', counts: 4758, color: 'danger' }
        ],
        en: [
          { orderType: 'Finished', counts: 23043, color: 'primary' },
          { orderType: 'Pending', counts: 14658, color: 'warning' },
          { orderType: 'Rejected ', counts: 4758, color: 'danger' }
        ],
        pt: [
          { orderType: 'Finalizado', counts: 23043, color: 'primary' },
          { orderType: 'Pendente', counts: 14658, color: 'warning' },
          { orderType: 'Rejeitado', counts: 4758, color: 'danger' }
        ]
      }
    },
    chartOptions: {
      es: {
        labels: ['Rechazados', 'Pendientes', 'Completados'],
        plotOptions: {
          radialBar: {
            size: 165,
            hollow: {
              size: '20%'
            },
            track: {
              background: '#ebebeb',
              strokeWidth: '100%',
              margin: 15
            },
            dataLabels: {
              show: true,
              name: {
                fontSize: '18px'
              },
              value: {
                fontSize: '16px',
                color: '#636a71',
                offsetY: 11
              },
              total: {
                show: true,
                label: 'Total',
                formatter: function() {
                  return 42459
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 576,
            options: {
              plotOptions: {
                radialBar: {
                  size: 150,
                  hollow: {
                    size: '20%'
                  },
                  track: {
                    background: '#ebebeb',
                    strokeWidth: '100%',
                    margin: 15
                  }
                }
              }
            }
          }
        ],
        colors: ['#7961F9', '#FF9F43', '#f29292'],
        fill: {
          type: 'gradient',
          gradient: {
            // enabled: true,
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: ['#9c8cfc', '#FFC085', '#EA5455'],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        chart: {
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      },
      en: {
        labels: ['Finished', 'Pending', 'Rejected'],
        plotOptions: {
          radialBar: {
            size: 165,
            hollow: {
              size: '20%'
            },
            track: {
              background: '#ebebeb',
              strokeWidth: '100%',
              margin: 15
            },
            dataLabels: {
              show: true,
              name: {
                fontSize: '18px'
              },
              value: {
                fontSize: '16px',
                color: '#636a71',
                offsetY: 11
              },
              total: {
                show: true,
                label: 'Total',
                formatter: function() {
                  return 42459
                }
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 576,
            options: {
              plotOptions: {
                radialBar: {
                  size: 150,
                  hollow: {
                    size: '20%'
                  },
                  track: {
                    background: '#ebebeb',
                    strokeWidth: '100%',
                    margin: 15
                  }
                }
              }
            }
          }
        ],
        colors: ['#7961F9', '#FF9F43', '#f29292'],
        fill: {
          type: 'gradient',
          gradient: {
            // enabled: true,
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: ['#9c8cfc', '#FFC085', '#EA5455'],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        chart: {
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      }
    },
    pt: {
      labels: ['Rejeitados', 'Pendentes', 'Concluídos'],
      plotOptions: {
        radialBar: {
          size: 165,
          hollow: {
            size: '20%'
          },
          track: {
            background: '#ebebeb',
            strokeWidth: '100%',
            margin: 15
          },
          dataLabels: {
            show: true,
            name: {
              fontSize: '18px'
            },
            value: {
              fontSize: '16px',
              color: '#636a71',
              offsetY: 11
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function() {
                return 42459
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 576,
          options: {
            plotOptions: {
              radialBar: {
                size: 150,
                hollow: {
                  size: '20%'
                },
                track: {
                  background: '#ebebeb',
                  strokeWidth: '100%',
                  margin: 15
                }
              }
            }
          }
        }
      ],
      colors: ['#7961F9', '#FF9F43', '#f29292'],
      fill: {
        type: 'gradient',
        gradient: {
          // enabled: true,
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#9c8cfc', '#FFC085', '#EA5455'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      chart: {
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1
        }
      }
    }
  },
  card7: {
    title: {
      es: 'Estadisticas de ventas',
      en: 'Sales Stats',
      pt: 'Estatísticas de vendas'
    },
    subtitle: {
      es: 'Últimos 6 meses',
      en: 'Last 6 Months',
      pt: 'Últimos 6 meses'
    },
    item1: {
      es: 'Ventas',
      en: 'Sales',
      pt: 'Vendas'
    },
    item2: {
      es: 'Visitas',
      en: 'Visits',
      pt: 'Visitas'
    },
    chartOptions: {
      es: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'Mar', 'Jun'],
        dataLabels: {
          style: {
            colors: [
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd'
            ]
          }
        },
        yaxis: {
          show: false
        },
        grid: {
          show: false
        },
        legend: { show: false },
        chart: {
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 0
        },
        colors: ['#9f8ed7', '#1edec5'],
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: [
                '#e8e8e8',
                'transparent',
                'transparent',
                'transparent',
                'transparent',
                'transparent'
              ],
              connectorColors: 'transparent'
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#8e9ad6', '#1fcadb'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0
        }
      },
      en: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        dataLabels: {
          style: {
            colors: [
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd',
              '#b9c3cd'
            ]
          }
        },
        yaxis: {
          show: false
        },
        grid: {
          show: false
        },
        legend: { show: false },
        chart: {
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 0
        },
        colors: ['#9f8ed7', '#1edec5'],
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: [
                '#e8e8e8',
                'transparent',
                'transparent',
                'transparent',
                'transparent',
                'transparent'
              ],
              connectorColors: 'transparent'
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#8e9ad6', '#1fcadb'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0
        }
      }
    },
    pt: {
      labels: ['Jan', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun'],
      dataLabels: {
        style: {
          colors: [
            '#b9c3cd',
            '#b9c3cd',
            '#b9c3cd',
            '#b9c3cd',
            '#b9c3cd',
            '#b9c3cd'
          ]
        }
      },
      yaxis: {
        show: false
      },
      grid: {
        show: false
      },
      legend: { show: false },
      chart: {
        dropShadow: {
          enabled: true,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      stroke: {
        width: 0
      },
      colors: ['#9f8ed7', '#1edec5'],
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: [
              '#e8e8e8',
              'transparent',
              'transparent',
              'transparent',
              'transparent',
              'transparent'
            ],
            connectorColors: 'transparent'
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#8e9ad6', '#1fcadb'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        }
      },
      markers: {
        size: 0
      }
    }
  },
  table: {
    title: {
      es: 'Vista general',
      en: 'Overview',
      pt: 'Visão geral'
    },
    tableTitles: {
      es: [
        'CHATBOT',
        'ESTADO',
        'OPERADORES',
        'LOCACIÓN',
        'TIEMPO DE RESPUESTA PROMEDIO',
        'TICKETS HOY',
        'TICKETS PROMEDIO/HORA'
      ],
      en: [
        'CHATBOT',
        'STATUS',
        'OPERATORS',
        'LOCATION',
        'AVG RESPONSE TIME',
        'TICKETS TODAY',
        'AVG TICKETS/HOUR'
      ],
      pt: [
        'CHATBOT',
        'STATUS',
        'OPERADORES',
        'LOCALIZAÇÃO',
        'TEMPO DE RESPONSTA AVG',
        'TICKETS DE HOJE',
        'TICKETS DE AVG/HORA'
      ]
    }
  },
  validationPie: {
    positive: {
      es: 'Positivas',
      en: 'Positive',
      pt: 'Positivo'
    },
    negative: {
      es: 'Negativas',
      en: 'Negative',
      pt: 'Negativo'
    },
    noAnswer: {
      es: 'Sin respuesta',
      en: 'No Answer',
      pt: 'Sem resposta'
    }
  },
  columns: {
    weekday: {
      es: 'Día',
      en: 'Weekday',
      pt: 'Dia da semana'
    },
    days: {
      es: 'Dias',
      en: 'Days',
      pt: 'Dias'
    },
    monday: {
      es: 'Lunes',
      en: 'Monday',
      pt: 'Segunda-feira'
    },
    tuesday: {
      es: 'Martes',
      en: 'Tuesday',
      pt: 'Terça-feira'
    },
    wednesday: {
      es: 'Miercoles',
      en: 'Wednesday',
      pt: 'Quarta-feira'
    },
    thursday: {
      es: 'Jueves',
      en: 'Thursday',
      pt: 'Quinta-feira'
    },
    friday: {
      es: 'Viernes',
      en: 'Friday',
      pt: 'Sexta-feira'
    },
    saturday: {
      es: 'Sabado',
      en: 'Saturday',
      pt: 'Sábado'
    },
    sunday: {
      es: 'Domingo',
      en: 'Sunday',
      pt: 'Domingo'
    }
  },
  messagesPerChannel: {
    es: 'Mensajes por canal',
    en: 'Messages per channel',
    pt: 'Mensagens por canal'
  },
  lastWeek: {
    es: 'Última semana',
    en: 'Last week',
    pt: 'Semana passada'
  },
  lastMonth: {
    es: 'Último mes',
    en: 'Last month',
    pt: 'Mês passado'
  },
  themes: {
    es: 'Temas',
    en: 'Themes',
    pt: 'Temas'
  },
  theme: {
    es: 'Tema',
    en: 'Theme',
    pt: 'Tema'
  },
  rangeSelect: {
    es: 'Seleccionar rango',
    en: 'Select range',
    pt: 'Selecione o intervalo'
  },
  options: {
    es: 'Opciones',
    en: 'Options',
    pt: 'Opções'
  },
  lastSevenDays: {
    es: 'Últimos 7 días',
    en: 'Last 7 days',
    pt: 'Últimos 7 dias'
  },
  derivationsPerChannel: {
    es: 'Derivaciones por canal',
    en: 'Referrals per channel',
    pt: 'Referências por canal'
  },
  mostUsedIntentions: {
    es: 'Intenciones más usadas',
    en: 'Most used intentions',
    pt: 'Intenções mais usadas'
  },
  averageTalkDurationPerChannel: {
    es: 'Duración promedio de conversación por canal',
    en: 'Average talk duration per channel',
    pt: 'Duração média da conversa por canal'
  },
  minutes: {
    es: 'Minutos',
    en: 'Minutes',
    pt: 'Minutos'
  },
  sendEmail: {
    success: {
      title: {
        es: 'Éxito',
        en: 'Sucess',
        pt: 'Sucesso'
      },
      text: {
        es: 'El correo fue enviado.',
        en: 'The email was sent',
        pt: 'O e-mail foi enviado'
      }
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!'
      },
      text: {
        es: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde',
        en: 'Something went wrong. Please try again later',
        pt: 'Algo deu errado. Por favor, tente de novo mais tarde'
      }
    }
  },
  emptyBot: {
    es: 'Oops! no existen bots, crea uno para comenzar!',
    en: 'Oops! There are no bots, create one to start!',
    pt: 'Oops! Não há bots, crie um para começar!'
  },
  createBot: {
    es: 'Crear Bot',
    en: 'Create Bot',
    pt: 'Criar Bot'
  },
  tabs: {
    general: {
      es: 'Generales',
      en: 'General',
      pt: 'Geral'
    },
    contact: {
      es: 'Contact center',
      en: 'Contact center',
      pt: 'Centro de contato'
    }
  },
  filters: {
    title: {
      es: 'Filtrar por',
      en: 'Filter by',
      pt: 'Filtrar por'
    },
    bot: {
      label: {
        es: 'Bot',
        en: 'Bot',
        pt: 'Bot'
      }
    },
    bots: {
      label: {
        es: 'Bots',
        en: 'Bots',
        pt: 'Bots'
      }
    },
    queue: {
      label: {
        es: 'Cola',
        en: 'Queue',
        pt: 'Fila'
      }
    },
    queues: {
      label: {
        es: 'Colas',
        en: 'Queues',
        pt: 'Filas'
      }
    },
    platform: {
      label: {
        es: 'Plataforma',
        en: 'Platform',
        pt: 'Plataforma'
      }
    },
    platforms: {
      label: {
        es: 'Plataformas',
        en: 'Platforms',
        pt: 'Plataformas'
      },
      labels: [
        {
          name: 'Alexa',
          code: 'alexa'
        },
        {
          name: 'Voice',
          code: 'voice'
        },
        {
          name: 'Facebook',
          code: 'facebook'
        },
        {
          name: 'RCS',
          code: 'rcs'
        },
        {
          name: 'Signal',
          code: 'signal'
        },
        {
          name: 'Telegram',
          code: 'telegram'
        },
        {
          name: 'Twitter',
          code: 'twitter'
        },
        {
          name: 'Web',
          code: 'web'
        },
        {
          name: 'WhatsApp',
          code: 'whatsapp'
        }
      ]
    },
    range: {
      lastWeek: {
        es: 'Últ. semana',
        en: 'Last week',
        pt: 'Semana passada'
      },
      lastMonth: {
        es: 'Últ. mes',
        en: 'Last month',
        pt: 'Mês passado'
      },
      date: {
        es: 'Fecha',
        en: 'Date',
        pt: 'Data'
      },
      rangeSeparator: {
        es: ' al ',
        en: ' to ',
        pt: ' para '
      },
      rangePlaceholder: {
        es: 'Seleccione rango de fechas',
        en: 'Select dates range',
        pt: 'Selecione datas'
      },
      clear: {
        es: 'Limpiar filtro',
        en: 'Clear filter',
        pt: 'Limpar filtro'
      },
      reset: {
        es: 'Última semana',
        en: 'Last week',
        pt: 'Semana passada'
      },
      messageDeadline: {
        es: 'La fecha límite para crear clusters es hoy.',
        en: 'The deadline to create clusters is today.',
        pt: 'O prazo para criar clusters é hoje.'
      }
    },
    report: {
      label: {
        es: 'Reportes',
        en: 'Reports',
        pt: 'Relatórios'
      },
      placeholder: {
        es: 'Buscar reporte',
        en: 'Search report',
        pt: 'Relatório de pesquisa'
      },
      selected: {
        es: 'reportes seleccionados',
        en: 'reports selected',
        pt: 'relatórios selecionados'
      }
    },
    intent: {
      label: {
        es: 'Intenciones',
        en: 'Intents',
        pt: 'Intenções'
      },
      placeholder: {
        single: {
          es: 'Seleccione intención',
          en: 'Select intent',
          pt: 'Selecione a intenção'
        },
        multiple: {
          es: 'Seleccione intenciones',
          en: 'Select intents',
          pt: 'Selecione as intenções'
        }
      },
      allIntents: {
        es: 'Todas las intenciones',
        en: 'All intents',
        pt: 'Todas as intenções'
      },
      notDetected: {
        es: 'No detectadas',
        en: 'Not detected',
        pt: 'Não detectado'
      }
    },
    survey: {
      label: {
        es: 'Encuesta',
        en: 'Survey',
        pt: 'Pesquisa'
      }
    },
    surveys: {
      label: {
        es: 'Encuestas',
        en: 'Surveys',
        pt: 'Pesquisas'
      },
      placeholder: {
        es: 'Buscar encuesta',
        en: 'Search survey',
        pt: 'Pesquisar'
      }
    },
    status: {
      label: {
        es: 'Estado',
        en: 'Status',
        pt: 'Status'
      },
      placeholder: {
        es: 'Buscar estado',
        en: 'Search status',
        pt: 'Status de pesquisa'
      }
    },
    statuses: {
      label: {
        es: 'Estados',
        en: 'Statuses',
        pt: 'Statuses'
      },
      placeholder: {
        es: 'Buscar estados',
        en: 'Search statuses',
        pt: 'Procure status'
      }
    },
    text: {
      label: {
        es: 'Contiene el texto',
        en: 'Contains text',
        pt: 'Contém texto'
      }
    },
    trainerStatus: {
      label: {
        es: 'Estado de entrenamiento',
        en: 'Training status',
        pt: 'Situação de treinamento'
      },
      placeholder: {
        es: 'Buscar estado',
        en: 'Search status',
        pt: 'Situação da pesquisa'
      }
    },
    companyStatus: {
      label: {
        es: 'Estado de Organización',
        en: 'Tenant status',
        pt: 'Situação de locatário'
      },
      placeholder: {
        es: 'Buscar Organización',
        en: 'Search Tenant',
        pt: 'Pesquisar Organização'
      }
    },
    version: {
      label: {
        es: 'Versión',
        en: 'Version',
        pt: 'Versão'
      },
      placeholder: {
        es: 'Seleccione versión',
        en: 'Select version',
        pt: 'Selecione a versão'
      }
    },
    noOptions: {
      es: 'No hay opciones',
      en: 'There are no options',
      pt: 'Não há opções'
    },
    staging: {
      label: {
        es: 'Mostrar Staging',
        en: 'Show Staging',
        pt: 'Mostre Staging'
      },
      show: {
        es: 'Mostrar',
        en: 'Show',
        pt: 'Mostrar'
      },
      hide: {
        es: 'Ocultar',
        en: 'Hide',
        pt: 'Esconder'
      }
    }
  },
  dataUpdated: {
    es: 'Datos actualizados ',
    en: 'Data updated ',
    pt: 'Dados atualizados'
  },
  lastUpdate: {
    es: 'Última actualización: ',
    en: 'Last update: ',
    pt: 'Última atualização:'
  },
  refresh: {
    es: 'Actualizar',
    en: 'Refresh',
    pt: 'Atualizar'
  },
  filterItems: {
    es: 'Seleccione items',
    en: 'Select items',
    pt: 'Selecione itens'
  },
  others: {
    es: 'otros',
    en: 'others',
    pt: 'outros'
  },
  reportMessages: {
    text: {
      es: 'Error al obtener los datos del gráfico ',
      en: 'Failed to get data from chart ',
      pt: 'Falha ao obter dados do gráfico'
    }
  }
}
