import Request from './request'
import state from '../store/state'

const PATH = {
  VARIABLE: '/variables'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getVariables = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.VARIABLE}`, config)
}

const getVariable = variableId => {
    const token = _getToken()
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    return Request.get(`${state.config.konecta}${PATH.VARIABLE}/${variableId}`, config)
}

const createVariable = payload => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.VARIABLE}`, payload, config)
}

const updateVariable = (id, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.VARIABLE}/${id}`, payload, config)
}

const deleteVariable = variableId => {
    const token = _getToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.delete(
      `${state.config.konecta}${PATH.VARIABLE}/${variableId}`, config
    )
  }

export default {
  getVariables,
  getVariable,
  createVariable,
  updateVariable,
  deleteVariable
}
