import Request from './request'
import state from '../store/state'
import axios from 'axios'
const CancelToken = axios.CancelToken

const PATH = {
  REPORT_DATA: '/report/query'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getReportData = (reportId, payload, executor) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
    cancelToken: new CancelToken(executor)
  }

  return Request.post(
    `${state.config.konecta}${PATH.REPORT_DATA}/${reportId}`,
    payload,
    config
  )
}

export default {
  getReportData: _getReportData
}
