import Helper from '../Helper'
import Vue from 'vue'
import { Intent } from '../../models/Intent'
import { UserMessage } from '../../models/Messages/UserMessage'
import { BotMessage } from '../../models/Messages/BotMessage'
import { MatchRegexMessageType } from '../../models/Messages/MessageTypes/MatchRegexMessageType'
import { IntentMessageType } from '../../models/Messages/MessageTypes/IntentMessageType'
import { GenericMessageType } from '../../models/Messages/MessageTypes/GenericMessageType'
import { conditionsFactory, botMessageValidationFactory } from '../factories'
import { CV_FXS, CV_TYPES_OPS, CV_OPTIONS } from '@/constants/constants'
import languages from '@/languages/languages'

const mutations = {
  START_STORE(state) {
    state.apiCalls = []
    state.entities = []
    state.variables = []
    state.bot = undefined
    state.botId = undefined
    state.messages = []
    state.botMakerMessages = Helper.loadMessages(state.messages)
    state.prevAlternativeMessages = []
    state.nextAlternativeMessages = []
    state.userMessageInEdit = new UserMessage()
    state.botMessageInEdit = new BotMessage()
    state.selectedIntention = {}
    state.intentions = []
    state.activeChannelView = 'all'
    state.sliderChange = {
      forward: null,
      msg_id: null
    }
    Vue.$log.info()
  },

  SLIDER_CHANGE(state, { forward, msg_id }) {
    state.sliderChange = {
      forward,
      msg_id
    }
  },

  UNSHIFT_VARIABLE(state, variable) {
    state.variables = [variable].concat(state.variables)
  },
  SPLICE_VARIABLE(state, index) {
    state.variables.splice(index, 1)
  },
  UPDATE_VARIABLE(state, variable) {
    let index = null
    state.variables.some((element, i) => {
      if (element._id === variable._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.variables[index] = variable
      const aux = JSON.parse(JSON.stringify(state.variables))
      state.variables = aux
    }
  },

  UNSHIFT_ENTITY(state, entity) {
    state.entities = [entity].concat(state.entities)
  },
  SPLICE_ENTITY(state, index) {
    state.entities.splice(index, 1)
  },
  UPDATE_ENTITY(state, entity) {
    let index = null
    state.entities.some((element, i) => {
      if (element._id === entity._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.entities[index] = entity
      const aux = JSON.parse(JSON.stringify(state.entities))
      state.entities = aux
      console.log('encontrado')
    }
    console.log('no encontrado')
  },

  UNSHIFT_API_CALL(state, apiCall) {
    state.apiCalls = [apiCall].concat(state.apiCalls)
  },
  SPLICE_API_CALL(state, index) {
    state.apiCalls.splice(index, 1)
  },
  UPDATE_API_CALL(state, apiCall) {
    let index = null
    state.apiCalls.some((element, i) => {
      if (element._id === apiCall._id) {
        index = i
      }
      return index !== null
    })
    if (index !== null) {
      state.apiCalls[index] = apiCall
      const aux = JSON.parse(JSON.stringify(state.apiCalls))
      state.apiCalls = aux
      console.log('encontrado')
    }
    console.log('no encontrado')
  },

  SET_BOT(state, bot) {
    state.bot = bot
  },

  SET_MESSAGE_IN_EDIT(state, messageId, responseId) {
    state.changedMessageInEdit = !state.changedMessageInEdit

    let message = Helper.searchMessage(state.messages, messageId)

    if (responseId !== undefined) {
      message = Helper.searchMessageByResponseId(state.messages, responseId)
    }

    const previousMessageEditing = Vue._.find(state.messages, m => {
      return m.isEditing
    })

    if (previousMessageEditing) {
      previousMessageEditing.isEditing = false
    }

    if (message) {
      message.isEditing = true
      if (message.isBotMessage()) {
        state.botMessageInEdit = message
        state.userMessageInEdit = new UserMessage()
        state.userMessageInEdit.isEditing = false

        // Update bot message tab depending on the message type
        if (message.type.isTextMessage()) {
          state.activeBotTab = 0
        } else if (message.type.isMediaMessage()) {
          state.activeBotTab = 1
        } else if (message.type.isButtonsMessage()) {
          state.activeBotTab = 1
        } else if (message.type.isCardMessage()) {
          state.activeBotTab = 1
        }

        // set validations
        const validationSettings =
          state.dialogsMap[message.dialogId].sendValidationSettings
        if (
          validationSettings &&
          validationSettings.validation &&
          validationSettings.validationEnabled
        ) {
          // status
          state.botValidationEnabled = validationSettings.validationEnabled
          // validation
          const dialogValidation = state.responseValidations.find(
            v => v._id === validationSettings.validation
          )
          state.botMessageValidation = Vue._.cloneDeep(dialogValidation) // Object.assign({}, dialogValidation)
        } else {
          // reset validations
          state.botValidationEnabled = false
          state.botMessageValidation = botMessageValidationFactory()
        }

        // RESET_CONDITIONS
        state.conditionsFilters = conditionsFactory()
        state.conditionName = null
        state.secondLevelAttention = false
        state.secondLevelAttentionHasChanged = false
        state.replyOutsideBusinessHours = false
        const unclassifiedText =
          languages.conditions.unclassified[state.bot.nlu.culture.split('-')[0]]
        state.conditionTags = [{ text: unclassifiedText }]
      } else {
        state.userMessageInEdit = message
        state.botMessageInEdit = new BotMessage()
        state.botMessageInEdit.isEditing = false
        // Load alternative paths
        let alternativePaths = Helper.getAlternativePaths(
          state.messages,
          message
        )
        Object.assign(state, alternativePaths)

        // Update user message active tab
        // if (message.type.isIntentMessage()) {
        //   state.activeUserTab = 0
        // } else if (message.type.isRegexMessage()) {
        //   state.activeUserTab = 1
        // }
      }
    }
  },

  SET_MESSAGE_IN_EDIT_RID(state, responseId) {
    // Es un poco extraño que no tenga el activeChannel cuando lo paso por param
    // En este caso hago un by-pass con el window
    let platform = window.activeChannelForce
    let message = Helper.searchMessageByResponseId(
      state.messages,
      responseId,
      platform
    )

    const previousMessageEditing = Vue._.find(state.messages, m => {
      return m.isEditing
    })

    if (previousMessageEditing) {
      previousMessageEditing.isEditing = false
    }

    if (message) {
      message.isEditing = true
      if (message.isBotMessage()) {
        state.botMessageInEdit = message
        state.userMessageInEdit = new UserMessage()
        state.userMessageInEdit.isEditing = false

        // Update bot message tab depending on the message type
        if (message.type.isTextMessage()) {
          state.activeBotTab = 0
        } else if (message.type.isMediaMessage()) {
          state.activeBotTab = 1
        } else if (message.type.isButtonsMessage()) {
          state.activeBotTab = 1
        }

        // RESET_CONDITIONS
        state.conditionsFilters = conditionsFactory()
        state.conditionName = null
        state.secondLevelAttention = false
        state.secondLevelAttentionHasChanged = false
        state.replyOutsideBusinessHours = false
        const unclassifiedText =
          languages.conditions.unclassified[state.bot.nlu.culture.split('-')[0]]
        state.conditionTags = [{ text: unclassifiedText }]
      } else {
        state.userMessageInEdit = message
        state.botMessageInEdit = new BotMessage()
        state.botMessageInEdit.isEditing = false
        // Load alternative paths
        let alternativePaths = Helper.getAlternativePaths(
          state.messages,
          message
        )
        Object.assign(state, alternativePaths)

        // Update user message active tab
        // if (message.type.isIntentMessage()) {
        //   state.activeUserTab = 0
        // } else if (message.type.isRegexMessage()) {
        //   state.activeUserTab = 1
        // }
      }
    }
  },

  SET_BOT_MESSAGE_BUTTONS(state, type) {
    state.botMessageInEdit.type.name = type.name
    state.botMessageInEdit.type.responseOptions = type.responseOptions
  },

  RESET_BOT_MESSAGE(state) {
    // state.activeBotTab = 0
    state.botMessageInEdit = new BotMessage()

    state.botValidationEnabled = false
    state.botMessageValidation = botMessageValidationFactory()
  },

  RESET_USER_MESSAGE(state /* activeTab */) {
    // state.activeUserTab = activeTab || 0
    state.userMessageInEdit = new UserMessage()
  },

  RESET_CONDITIONS(state) {
    state.conditionsFilters = conditionsFactory()
    state.conditionName = null
    state.secondLevelAttention = false
    state.secondLevelAttentionHasChanged = false
    state.replyOutsideBusinessHours = false

    const unclassifiedText =
      state.bot && state.bot.nlu && state.bot.nlu.culture
        ? languages.conditions.unclassified[state.bot.nlu.culture.split('-')[0]]
        : languages.conditions.unclassified['en']
    state.conditionTags = [{ text: unclassifiedText }]
  },

  ADD_MESSAGE(state, message) {
    Vue.$log.info('Adding message', message)

    if (state.botMakerMessages.length !== 0) {
      let previousMessage =
        state.botMakerMessages[state.botMakerMessages.length - 1]
      previousMessage.nextMessage = message.id
      message.prevMessage = previousMessage.id
    }

    state.botMakerMessages.push(message)

    // Store the message where all the messages live
    state.messages.push(message)
    Vue.$log.info('Added message to the list of messages', message.id)
  },

  SET_BOT_ID(state, botId) {
    state.botId = botId
  },

  UPDATE_MESSAGE(state, message) {
    message.isEditing = false

    if (message.isBotMessage()) {
      state.botMessageInEdit = new BotMessage()
    } else {
      state.userMessageInEdit = new UserMessage()
    }
  },

  /**
   * Create alternative path
   * @param state {Object} the current state
   * @param message {Message} the current message
   */
  CREATE_ALTERNATIVE_PATH(state, message) {
    // save last visited dialogSavedId for get dialogs in the case
    // new alt is removed before being saved
    state.lastAlternativeDialogSavedId =
      state.botMakerMessages && state.botMakerMessages[0].dialogSavedId
    let loadFrom = undefined
    let tmpMessage = new UserMessage() // This is the new message to be inserted

    // The new message (tmpMessage) has as next message the bifurcation message
    tmpMessage.nextAlternative = message.nextAlternative
    // The old message has as next message the new message
    message.nextAlternative = tmpMessage.id
    // The new message previous message would be the root
    tmpMessage.prevAlternative = message.id
    // Assign the previous message
    tmpMessage.prevMessage = message.prevMessage

    if (tmpMessage.nextAlternative) {
      let nextMessage = Helper.searchMessage(
        state.messages,
        tmpMessage.nextAlternative
      )
      nextMessage.prevAlternative = tmpMessage.id
    }

    if (message.prevMessage) {
      let prevMessage = Helper.searchMessage(
        state.messages,
        message.prevMessage
      )
      prevMessage.nextMessage = tmpMessage.id
      // loadFrom = state.botMakerMessages[0].id
      loadFrom = prevMessage.id
    } else {
      loadFrom = tmpMessage.id
    }

    state.messages.push(tmpMessage)

    // Set the new alternative message as the one for editing
    state.userMessageInEdit = new UserMessage()
    state.activeUserTab = 0
    state.userMessageInEdit.alternativeMessage = tmpMessage.id
    tmpMessage.isEditing = false

    // Load previous path for displaying it on the view
    state.prevAlternativeMessages = Helper.loadMessages(
      state.messages,
      message.id
    )

    Helper.updateRelatePathCounters(state.messages)

    // Load messages
    state.botMakerMessages = Helper.loadMessages(state.messages, loadFrom)
  },

  LOAD_NEXT_ALTERNATIVE_PATH(state, message) {
    let loadFrom = undefined
    // If there is no new alternative path
    let nextAlternativeMessage = Helper.searchMessage(
      state.messages,
      message.nextAlternative
    )

    if (message.prevMessage) {
      loadFrom = message.prevMessage
      let previousMessage = Helper.searchMessage(
        state.messages,
        message.prevMessage
      )
      previousMessage.nextMessage = message.nextAlternative
    } else {
      loadFrom = message.nextAlternative
    }

    message.isEditing = false
    nextAlternativeMessage.isEditing = true

    // if (nextAlternativeMessage.type.isIntentMessage()) {
    //   state.activeUserTab = 0
    // } else {
    //   state.activeUserTab = 1
    // }

    state.userMessageInEdit = nextAlternativeMessage

    // Load previous and next alternative paths
    state.prevAlternativeMessages = Helper.loadMessages(
      state.messages,
      nextAlternativeMessage.prevAlternative
    )

    // Load alternative path
    let alternativePaths = Helper.getAlternativePaths(
      state.messages,
      nextAlternativeMessage
    )
    Object.assign(state, alternativePaths)

    // Load messages
    // If the message is a root message, meaning that it has no previous message, the messages should be loaded from the new message onwards
    if (loadFrom) {
      state.botMakerMessages = Helper.loadMessages(state.messages, loadFrom)
    } else {
      state.botMakerMessages = Helper.loadMessages(state.messages)
    }
  },

  LOAD_ALTERNATIVE_PATH(state, message) {
    let loadFrom = message.id

    if (message.prevMessage) {
      loadFrom = state.botMakerMessages[0].id
      let previousMessage = Helper.searchMessage(
        state.messages,
        message.prevMessage
      )
      previousMessage.nextMessage = message.nextAlternative
    }

    message.isEditing = true

    // if (message.type.isIntentMessage()) {
    //   state.activeUserTab = 0
    // } else {
    //   state.activeUserTab = 1
    // }

    state.userMessageInEdit = message

    // Load previous and next alternative paths
    state.prevAlternativeMessages = Helper.loadMessages(
      state.messages,
      message.prevAlternative
    )

    // Load alternative path
    let alternativePaths = Helper.getAlternativePaths(state.messages, message)
    Object.assign(state, alternativePaths)

    // Load messages
    // If the message is a root message, meaning that it has no previous message, the messages should be loaded from the new message onwards
    if (loadFrom) {
      state.botMakerMessages = Helper.loadMessages(state.messages, loadFrom)
    } else {
      state.botMakerMessages = Helper.loadMessages(state.messages)
    }
  },

  REMOVE_MESSAGE(state, message) {
    let loadFrom = undefined
    let prevMessage = Helper.searchMessage(state.messages, message.prevMessage)
    let prevAlternative = Helper.searchMessage(
      state.messages,
      message.prevAlternative
    )
    let nextAlternative = Helper.searchMessage(
      state.messages,
      message.nextAlternative
    )
    if (message.prevMessage) {
      loadFrom = state.botMakerMessages[0].id
    } else if (message.prevAlternative) {
      loadFrom = message.prevAlternative
    }

    if (message.prevMessage) {
      prevMessage.nextMessage = message.prevAlternative
        ? message.prevAlternative
        : message.nextMessage
    }

    if (message.prevAlternative) {
      prevAlternative.prevMessage = message.prevMessage
      prevAlternative.nextAlternative = message.nextAlternative
    }

    if (message.nextAlternative) {
      nextAlternative.prevAlternative = message.prevAlternative
    }

    // TODO: next line needs to be commented because loadFrom doesnt support unsaved messages
    state.botMakerMessages = Helper.loadMessages(state.messages, loadFrom)
  },

  LOAD_PREV_ALTERNATIVE_PATH(state, message) {
    let prevAlternativeMessage = Helper.searchMessage(
      state.messages,
      message.prevAlternative
    )

    // When the bot does not have a previous message
    // the active bot messages should be loaded from the root
    let loadFrom = undefined
    if (message.prevMessage) {
      let previousMessage = Helper.searchMessage(
        state.messages,
        message.prevMessage
      )
      previousMessage.nextMessage = message.prevAlternative
      // loadFrom = state.botMakerMessages[0].id
      loadFrom = message.prevMessage
    } else {
      loadFrom = message.prevAlternative
    }

    message.isEditing = false
    prevAlternativeMessage.isEditing = true
    // if (prevAlternativeMessage.type.isIntentMessage()) {
    //   state.activeUserTab = 0
    // } else {
    //   state.activeUserTab = 1
    // }
    state.userMessageInEdit = prevAlternativeMessage

    // If the previous message is a root one, we'll need to load from the new root
    if (loadFrom) {
      state.botMakerMessages = Helper.loadMessages(state.messages, loadFrom)
    } else {
      state.botMakerMessages = Helper.loadMessages(state.messages)
    }

    // Load alternative paths
    let alternativePaths = Helper.getAlternativePaths(
      state.messages,
      prevAlternativeMessage
    )
    Object.assign(state, alternativePaths)
  },

  TOGGLE_ANIMATION_BUTTON(state) {
    state.animateButtons = !state.animateButtons
  },

  SET_VERSION(state, { versionId, versionFork, forkTemplate }) {
    state.bot.activeVersion = versionId
    state.bot.fork = versionFork
    state.bot.forkTemplate = forkTemplate
    Vue.$log.info(versionId)
  },

  SET_FORK(state, fork) {
    state.bot.fork = fork
    Vue.$log.info(fork)
  },

  // Intentions

  SET_INTENTIONS(state, intentions) {
    state.intentions = intentions.map(intent => {
      return new Intent(
        intent.name,
        intent.examples,
        intent.derivesToAgent,
        intent._id
      )
    })
    Vue.$log.info(intentions)
  },

  SET_INTENTION_SELECTED(state, intention = {}) {
    state.selectedIntention = Vue._.cloneDeep(intention)
    state.activeIntention = intention && intention.id ? intention.id : null
  },

  ADD_INTENTION(state, intention) {
    Vue.$log.info(intention)
    let intentTmp = new Intent(
      intention.name,
      intention.examples,
      false,
      intention._id
    )
    if (state.intentions.length === 0) {
      state.intentions.push(intentTmp)
    } else {
      let storedIntention = state.intentions.find(i => i.id === intention.id)
      if (storedIntention) {
        storedIntention.name = intentTmp.name
        storedIntention.examples = intentTmp.examples
      }
    }
  },

  UPDATE_INTENTS(state, anIntent) {
    let intent = state.intentions.find(i => {
      return i.name.toLowerCase() === anIntent.name.toLowerCase()
    })

    // update alternative path intents
    if (!intent && anIntent.intentId) {
      intent = state.intentions.find(i => {
        return i.id === anIntent.intentId
      })
    }

    intent.name = anIntent.name
    intent.examples = anIntent.examples
    intent.derivesToAgent = anIntent.derivesToAgent

    let messages = state.messages.filter(message => {
      return (
        message.type.name.toLowerCase() === anIntent.name.toLowerCase() &&
        message.isUserMessage()
      )
    })
    messages.forEach(message => {
      message.type.name = anIntent.name
      message.type.examples = anIntent.examples
      message.type.secondLevel = anIntent.derivesToAgent
    })
  },

  UPDATE_USER_MESSAGE_TYPE(state, type) {
    if (type === 'text') {
      state.userMessageInEdit.type = new IntentMessageType()
    } else if (type === 'regex') {
      state.userMessageInEdit.type = new MatchRegexMessageType()
    } else {
      state.userMessageInEdit.type = new GenericMessageType()
    }
  },

  // For keeping message type tabs
  UPDATE_USER_TAB_INDEX(state, tabIndex) {
    state.activeUserTab = tabIndex
  },

  UPDATE_BOT_TAB_INDEX(state, tabIndex = 0) {
    state.activeBotTab = tabIndex
  },

  SET_DIALOGS(state, dialogs) {
    state.dialogs = dialogs
  },

  SET_DIALOG_MSG_ID(state, dialogMsgId) {
    state.dialogMsgId = dialogMsgId
  },

  SET_DIALOG_JUMPS(state, currentDialogHasJumps = undefined) {
    state.currentDialogHasJumps = currentDialogHasJumps
  },

  SET_DIALOG_SURVEYS(state, currentDialogHasSurveys = undefined) {
    state.currentDialogHasSurveys = currentDialogHasSurveys
  },

  SET_DIALOG_VALIDATION(state, currentDialogHasValidation = undefined) {
    state.currentDialogHasValidation = currentDialogHasValidation
  },

  LAST_ALTERNATIVE_DIALOG_SAVED_ID(state, id = undefined) {
    state.lastAlternativeDialogSavedId = id
  },

  SET_CONDITIONS_IN_EDIT(state, message) {
    const conditions = state.dialogsMap[message.dialogId].condition
    const conditionsFilters = conditionsFactory()

    // extracted code

    // const params = conditions.params[1]
    const params = conditions.params
    const types = []
    const operators = [conditions.tree.operator]
    let fxs = []

    if (params && (typeof params === 'object' || params.length > 0)) {
      if (params instanceof Array) {
        parseConditions(params, types)
        let fn = conditions.tree.funcs[0]
        if (CV_FXS.includes(fn)) {
          fxs.push(fn)
        } else {
          fxs.push('')
        }
        fn = conditions.tree.funcs[1]
        if (fn && fn !== 'marina' && CV_FXS.includes(fn)) {
          fxs.push(fn)
        }

        const tree = conditions.tree.facts[1]
        parseTree(tree, operators, fxs)
      } else {
        types.push(params)
      }
    }

    // convert types a conditionsFilters
    types.forEach((t, index) => {
      if (t.entity) {
        const entity = state.conditionsGroups.entities.filter(
          e => e.text === t.entity
        )
        conditionsFilters.types.push(entity[0])
        conditionsFilters.values.push(t.value)
        conditionsFilters.values2.push('')
        conditionsFilters.cvTypes.push('')
      }

      if (t.regex) {
        conditionsFilters.types.push(state.conditionsGroups.regex)
        conditionsFilters.values.push(t.regex)
        conditionsFilters.values2.push('')
        conditionsFilters.cvTypes.push('')
      }

      if (t.intent) {
        const intent =
          state.conditionsGroups &&
          state.conditionsGroups.intentions &&
          state.conditionsGroups.intentions.filter(i => i.text === t.intent)

        if (intent && intent[0]) {
          conditionsFilters.types.push(intent[0])
        } else {
          conditionsFilters.types.push(t.intent)
        }
        conditionsFilters.values.push('')
        conditionsFilters.values2.push('')
        conditionsFilters.cvTypes.push('')
      }

      if (t.arg0) {
        t.type
          ? conditionsFilters.cvTypes.push(t.type)
          : conditionsFilters.cvTypes.push('')
        conditionsFilters.values.push(t.arg0)
        t.arg1
          ? conditionsFilters.values2.push(t.arg1)
          : conditionsFilters.values2.push('')

        conditionsFilters.types.push({
          type: 'type',
          text: fxs[index],
          value: '',
          value2: '',
          typeOp: CV_TYPES_OPS[fxs[index]]
        })
      }
    })

    conditionsFilters.operators = [''].concat(operators)

    state.conditionName = state.dialogsMap[message.dialogId].name
    state.secondLevelAttention = state.dialogsMap[message.dialogId].execTakeover
    state.secondLevelAttentionHasChanged = false
    state.replyOutsideBusinessHours =  state.dialogsMap[message.dialogId].replyOutsideBusinessHours
    state.conditionTags = [...state.dialogsMap[message.dialogId].tags]
    state.conditionsFilters = conditionsFilters
  },

  SET_TAGS_IN_EDIT(state, message) {
    state.conditionTags = [...state.dialogsMap[message.dialogId].tags]
  },

  MAP_CONDITIONS_OPTIONS(state) {
    state.conditionsGroups = {
      intentions: state.intentions.map(i => {
        return {
          type: 'intent',
          text: i.name,
          id: i.id,
          value: ''
        }
      }),
      entities: state.entities.map(e => {
        return {
          type: 'entity',
          text: e.name,
          id: e.entityId,
          value: ''
        }
      }),
      variables: state.variables.map(v => {
        return {
          type: 'variable',
          text: v.label,
          id: v.key,
          value: ''
        }
      }),
      regex: {
        type: 'match',
        text: 'RegEx',
        value: ''
      },
      type: CV_OPTIONS
    }
  },

  SET_CONDITIONS(state, payload) {
    state.conditionsFilters = payload
  },

  SET_MESSAGE_NAME(state, name) {
    state.userMessageInEdit.type.name = name
  },

  // ENTITIES
  SET_ENTITY_SELECTED(state, entity = {}) {
    state.selectedEntity = entity
    state.activeEntity = entity && entity.entityId ? entity.entityId : null
  },

  SET_NEW_ENTITY_NAME(state, name = null) {
    state.newEntityName = name
  },

  SET_CONDITION_NAME(state, conditionName) {
    state.conditionName = conditionName
  },

  SET_SECOND_LEVEL_ATTENTION(state, value) {
    state.secondLevelAttention = value
  },

  SET_SECOND_LEVEL_ATTENTION_HAS_CHANGED(state, value) {
    state.secondLevelAttentionHasChanged = value
  },

  SET_REPLY_OUTSIDE_BUSINESS_HOURS(state, value) {
    state.replyOutsideBusinessHours = value
  },

  RESET_CONDITION_TAGS(state) {
    const unclassifiedText =
      languages.conditions.unclassified[state.bot.nlu.culture.split('-')[0]]
    state.conditionTags = [{ text: unclassifiedText }]
  },

  SET_CONDITION_TAGS(state, tags) {
    state.conditionTags = tags
  },

  SET_CONDITION_TAG(state, tag) {
    state.conditionTags.splice(state.conditionTags.length, 0, tag)
  },

  DELETE_CONDITION_TAG(state, tag) {
    const tagIndex = state.conditionTags.findIndex(t => t.text === tag.text)
    state.conditionTags.splice(tagIndex, 1)
  },

  SET_ACTIVE_CHANNEL_VIEW(state, activeType = 'all') {
    state.activeChannelView = activeType
  },

  // SET_RESPONSE_DIALOG_ID(state, dialogSavedId) {
  //   state.responseDialogId = dialogSavedId
  // },

  SET_RESPONSE_ACTIVE(state, msg = null) {
    let index = null
    let response = null
    if (msg) {
      index = state.dialogsMap[
        state.botMessageInEdit.dialogId
      ].responses.findIndex(resp => resp._id === msg.type._id)

      response = state.dialogsMap[
        state.botMessageInEdit.dialogId
      ].responses.find(resp => resp._id === msg.type._id)
    }

    state.responseIndex = index
    state.selectedResponse = response
  },

  UPDATE_DIALOG_MAP_BY_DIALOG_ID(state, updatedDialog) {
    state.dialogsMap[updatedDialog.dialogId] = updatedDialog
  },

  RESET_BOT_MESSAGE_VALIDATION(state) {
    state.botMessageValidation = botMessageValidationFactory()
  },

  SET_VALIDATION_STATUS(state, status) {
    state.botValidationEnabled = status
  },

  SET_VALIDATION_APPLY_ALL(state, value) {
    state.botMessageValidationApplyAll = value
  },

  SET_RESPONSE_VALIDATION(state, validation) {
    state.responseValidations.push(validation)
  },

  UPDATE_RESPONSE_VALIDATION(state, validation) {
    const validationIndex = state.responseValidations.findIndex(
      v => v._id === validation._id
    )
    state.responseValidations[validationIndex] = validation
  },
  SET_CREDENTIAL(state, credential) {
    if (state.bot) {
      state.bot.credential = credential
    }
  },
  SET_RESPONSE_VALIDATIONS(state, validations) {
    state.responseValidations = validations
  },

  SET_VALIDATION_UPDATE_MODE(state, validationUpdateMode) {
    state.validationUpdateMode = validationUpdateMode
  },

  SET_INTENTIONS_BY_VERSION(state, { versionId, intentions }) {
    state.intentionsMap[versionId] = intentions
  },

  SET_BOT_VERSIONS(state, botVersions) {
    state.botVersions = botVersions
  },

  ADD_BOT_VERSION(state, version) {
    state.botVersions.push(version)
  },

  SET_CYBERBANK_VARIABLES(state, cyberbankVariables) {
    state.cyberbankVariables = cyberbankVariables
  }
}

function parseConditions(params, currentTypes = []) {
  currentTypes.push(params[0])
  if (params[1] instanceof Array) {
    parseConditions(params[1], currentTypes)
  } else {
    if (params[1] !== undefined) {
      currentTypes.push(params[1])
    }
  }
}

function parseTree(tree, operators = [], fxs) {
  if (tree) {
    let fn = tree.funcs[0]
    if (CV_FXS.includes(fn)) {
      fxs.push(fn)
    } else {
      fxs.push('')
    }
    fn = tree.funcs[1]
    if (fn && fn !== 'marina' && CV_FXS.includes(fn)) {
      fxs.push(fn)
    }
    if (typeof tree.facts[1] === 'object') {
      operators.push(tree.operator)
      parseTree(tree.facts[1], operators, fxs)
    }
  }
}

export default mutations
