/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import state from './state'
// import getters from "./getters"
import mutations from './mutations'
import actions from './actions'

import moduleChat from './chat/moduleChat'
import moduleBotMaker from './botMaker/moduleBotMaker'
import moduleBots from './bots/moduleBots'
import moduleCredentials from './credentials/moduleCredentials'
import moduleIntegrations from './integrations/moduleIntegrations'
import moduleIpRanges from './ipRanges/moduleIpRanges'
import audiences from './audiences'
import clusters from './clusters'
import queues from './queues'
import surveyMetrics from './surveyMetrics'
import contact from './contact'
import metrics from './metrics'
import companies from './companies'

export const strict = false

export default new Vuex.Store({
  // getters,
  mutations,
  state,
  actions,
  modules: {
    chat: moduleChat,
    botMaker: moduleBotMaker,
    bots: moduleBots,
    credentials: moduleCredentials,
    integrations: moduleIntegrations,
    ipRanges: moduleIpRanges,
    audiences: audiences,
    clusters: clusters,
    surveyMetrics: surveyMetrics,
    contact: contact,
    metrics,
    companies,
    queues
  },
  strict
  // strict: process.env.NODE_ENV !== 'production'
})
