// Helper.js

import Vue from 'vue'
import { Dialog } from '../models/Dialog'
import { UserMessage } from '../models/Messages/UserMessage'
import { TextMessageType } from '../models/Messages/MessageTypes/TextMessageType'
// import { IntentMessageType } from '../models/Messages/MessageTypes/IntentMessageType'
import { MediaMessageType } from '../models/Messages/MessageTypes/MediaMessageType'
import { ButtonsMessageType } from '../models/Messages/MessageTypes/ButtonsMessageType'
import { BotMessage } from '../models/Messages/BotMessage'

import { CardMessageType } from '../models/Messages/MessageTypes/CardMessageType'
// import { MatchRegexMessageType } from '../models/Messages/MessageTypes/MatchRegexMessageType'
// import { RegExpMessageType } from '../models/Messages/MessageTypes/RegExpMessageType'
import { GenericMessageType } from '../models/Messages/MessageTypes/GenericMessageType'
import timeZones from '@/constants/_TIME_ZONES.json'
import store from './store'

import {
  TYPES_SYMBOLS,
  CV_TYPES_SYMBOLS,
  CV_UNARY_FXS,
  SPECIAL_SYMBOLS,
  FILTER_TYPES
} from '@/constants/constants'

/**
 * Function for generating a random ID for new messages
 * @returns String
 */
function _generateID() {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

/**
 * Load connected messages
 * @param {Array<Message>} messages all the messages available
 * @param {String} startingNodeId the first node from which the tree has to be loaded
 * @param dialogSavedId {String} the dialog saved ID
 * @return {Array<Messages>} all the messages that are interconnected
 */
function _loadConnectedMessages(
  messages,
  startingNodeId = undefined,
  dialogSavedId = undefined
) {
  // If there are pre-existing messages
  if (messages.length) {
    let tmpMessages = []
    let finished = false
    let rootMessages = undefined

    if (startingNodeId) {
      rootMessages = messages.filter(message => {
        return message.id === startingNodeId
      })
    } else if (dialogSavedId) {
      rootMessages = messages.filter(message => {
        return (
          message.dialogSavedId === dialogSavedId && message.isUserMessage()
        )
      })
    } else {
      rootMessages = messages.filter(message => !message.prevMessage)
    }

    if (rootMessages.length > 0) {
      let message = rootMessages[0]

      if (rootMessages[0].prevMessage) {
        // Isn't root message
        const _getPrev = id => {
          const m = _searchMessage(messages, id)
          if (!m) {
            return []
          }
          if (!m.prevMessage) {
            return [m]
          }
          return [m].concat(_getPrev(m.prevMessage))
        }
        tmpMessages = _getPrev(rootMessages[0].prevMessage).reverse()
      }

      while (!finished) {
        // Add the message to the tmp array
        tmpMessages.push(message)

        // Only finish when there are no more messages interconnected
        if (!message.nextMessage) {
          finished = true
        } else {
          // Search for the next message
          message = _searchMessage(messages, message.nextMessage)
        }
      }
    }

    // If there are no active root message, then return an empty array;
    return tmpMessages
  }

  return []
}

/**
 * Search Message
 * @param {Array<Message>} messages an array containing all the messages
 * @param {String} id the ID of the message to be searched
 * @return {Message | Null}
 */
function _searchMessage(messages, id) {
  return Vue._.find(messages, m => {
    return m.id === id
  })
}

// Obtengo mensajes por responseId y plataforma
function _searchMessageByResponseId(messages, id, platform) {
  let find = Vue._.find(messages, m => {
    let checkCond =
      m.type.responseId === id && m.type.platforms.includes(platform)
    if (checkCond) {
      return true
    }
  })
  // Si no encuentro el mensaje por responseId, me quedo con el primero que encuentre
  if (!find) {
    return Vue._.find(messages, m => {
      let checkCond = m.type.responseId === id
      if (checkCond) {
        return true
      }
    })
  } else {
    return find
  }
}

/**
 * Get alternative paths
 * @params {Array<Message>} messages the messages available on the bot
 * @param messages
 * @param {Message} message the current message in edit
 * @returns {Object} with previos alternative and next alternative set of messages
 */
function _getAlternativePath(messages, message) {
  let response = {
    prevAlternativeMessages: [],
    nextAlternativeMessages: []
  }

  if (message.prevAlternative) {
    response.prevAlternativeMessages = _loadConnectedMessages(
      messages,
      message.prevAlternative
    )
  }

  if (message.nextAlternative) {
    response.nextAlternativeMessages = _loadConnectedMessages(
      messages,
      message.nextAlternative
    )
  }

  return response
}

/**
 * Update the relative path counters
 * @param messages {Array<Message>}
 * @private
 */
function _updateRelatePathCounters(messages) {
  let userMessages = messages.filter(m => m.isUserMessage())
  userMessages.forEach(message => {
    _countRelatives(messages, message)
  })
}

/**
 * Count the number of relatives and set it to the message
 * @param {Array<Message>} messages the messages available in the bot
 * @param {Message} message the last message added in the relative chain
 */
function _countRelatives(messages, message) {
  if (
    messages.length &&
    message.isUserMessage() &&
    (message.prevAlternative || message.nextAlternative)
  ) {
    let tmpMessage = message
    let prevMessages = []
    let nextMessages = []

    while (tmpMessage.prevAlternative) {
      prevMessages.unshift(tmpMessage)
      tmpMessage = _searchMessage(messages, tmpMessage.prevAlternative)
    }

    tmpMessage = message
    while (tmpMessage.nextAlternative) {
      nextMessages.push(tmpMessage)
      tmpMessage = _searchMessage(messages, tmpMessage.nextAlternative)
    }

    message.numberOfPrevAltPaths = prevMessages.length
    message.numberOfNextAltPaths = nextMessages.length
  }
}

const _generateDialogToSave = (message, versionId, intentName) => {
  if (message.isUserMessage() && intentName) {
    return new Dialog(versionId, message.type.name, [], intentName)
  }
  return new Dialog(versionId, message.type.name)
}

/**
 * Search for intention
 * @param {String} intentionName the name of the intention
 * @param {Array<Object>} intentions the intentions available
 * @returns {Object}
 */
// const _searchIntention = (intentionName, intentions) => {
//   return Vue._.find(intentions, intention => {
//     return intention.name.toLowerCase() === intentionName.toLowerCase()
//   })
// }

/**
 * Search for a dialog by ID
 * @param {String} dialogId the dialog ID that wants to be search
 * @param {Array<Dialog>} dialogs the collection of dialogs
 */
const _searchForDialogByDialogId = (dialogId, dialogs) => {
  return Vue._.find(dialogs, dialog => {
    return dialog.dialogId === dialogId
  })
}

/**
 * Generate user message from intentions
 * @param {String} intentionName the name of the intention
 * @param {Array<Object>} intentions all the intentions available for the bot
 * @param {Object} dialog the dialog
 */
// const _generateIntentUserMessage = (intentionName, intentions, dialog) => {
//   let intention = _searchIntention(intentionName, intentions)
//   if (!intention) {
//     intention = {
//       name: '',
//       id: undefined,
//       examples: [],
//       secondLevel: false
//     }
//   }

//   // TODO FIX
//   let type = new IntentMessageType(
//     intention.id,
//     intention.name,
//     intention.examples,
//     intention.derivesToAgent
//   )
//   let userMessage = new UserMessage(dialog.idUserMessage, dialog._id, type)
//   userMessage.dialogId = dialog.dialogId
//   userMessage.dialogSavedId = dialog._id
//   userMessage.isSaved = true

//   return userMessage
// }

// function _generateRegexUserMessage(dialog) {
//   // support for old dialogs
//   let regex =
//     dialog.condition.params.regex ||
//     (dialog.condition.params[0] && dialog.condition.params[0].regex)
//   let messageType = undefined
//   if (regex.indexOf('|') !== -1) {
//     let regexTmp = regex.replace('^(', '')
//     regexTmp = regexTmp.replace(')$', '')
//     let examples = regexTmp.split('|')
//     messageType = new MatchRegexMessageType(regex, examples)
//   } else {
//     messageType = new RegExpMessageType(regex)
//   }

//   let userMessage = new UserMessage(
//     dialog.idUserMessage,
//     dialog._id,
//     messageType
//   )
//   userMessage.dialogId = dialog.dialogId
//   userMessage.dialogSavedId = dialog._id
//   userMessage.isSaved = true

//   return userMessage
// }

function _isOldFormattedMessage(params) {
  return !Array.isArray(params)
}

function _generateGenericUserMessage(dialog) {
  let name
  const params = dialog.condition.params

  if (_isOldFormattedMessage(params)) {
    if (params.intent) {
      name = `${TYPES_SYMBOLS[FILTER_TYPES.intent]}${params.intent}`
    } else {
      name = `RegEx:${params.regex}`
    }
  } else {
    name = dialog.name
  }
  let messageType = new GenericMessageType(name)
  let userMessage = new UserMessage(
    dialog.idUserMessage,
    dialog._id,
    messageType
  )

  userMessage.dialogId = dialog.dialogId
  userMessage.dialogSavedId = dialog._id
  userMessage.isSaved = true
  userMessage.condition = dialog.condition

  return userMessage
}

function _isMediaMessage(botDialog) {
  return botDialog.responseFile && botDialog.responseFile.url
}

function _isButtonsMessage(botDialog) {
  return botDialog.responseOptions
}

function _isCardMessage(botDialog) {
  return botDialog.responseCard
}

const _generateBotMessages = dialog => {
  let botMessages = []
  if (dialog.responses && dialog.responses.length > 0) {
    dialog.responses.forEach((botDialog, index) => {
      let messageType = undefined

      if (_isMediaMessage(botDialog)) {
        const backendBaseUrl = store.state.config.konecta
        const responseFileUrl = /^\/bucket/.test(botDialog.responseFile.url)
          ? `${backendBaseUrl}${botDialog.responseFile.url}`
          : botDialog.responseFile.url

        messageType = new MediaMessageType(
          botDialog.responseFile.name || botDialog.responseText,
          '',
          responseFileUrl,
          botDialog.responseFile.type,
          botDialog.platforms,
          botDialog._id,
          botDialog.responseId,
          botDialog.beforeAPICall
        )
      } else if (_isButtonsMessage(botDialog)) {
        messageType = new ButtonsMessageType(
          botDialog.responseText,
          botDialog.responseOptions,
          botDialog.platforms,
          botDialog._id,
          botDialog.responseId,
          botDialog.beforeAPICall
        )
      } else if (_isCardMessage(botDialog)) {
        if (botDialog.responseCard) {
          const backendBaseUrl = store.state.config.konecta
          botDialog.responseCard.forEach(c => {
            if (c.header && c.header.url) {
              const responseCardUrl = /^\/bucket/.test(c.header.url)
                ? `${backendBaseUrl}${c.header.url}`
                : c.header.url
              c.header.url = responseCardUrl
            }
          })
        }
        messageType = new CardMessageType(
          botDialog.responseText,
          botDialog.responseCard,
          botDialog.platforms,
          botDialog._id,
          botDialog.responseId,
          botDialog.beforeAPICall
        )
      } else {
        messageType = new TextMessageType(
          botDialog.responseText,
          botDialog.platforms,
          botDialog._id,
          botDialog.responseId,
          botDialog.beforeAPICall
        )
      }

      let botMessage = new BotMessage(
        dialog.idBotMessages[index],
        dialog._id,
        messageType
      )

      botMessage.dialogId = dialog.dialogId
      botMessage.isSaved = true
      botMessages.push(botMessage)
    })

    // Link bot messages
    for (let i = 0; i < botMessages.length; i++) {
      let message = botMessages[i]

      // When it is not the first message
      if (i !== 0) {
        // Link with previous dialog ID
        message.prevMessage = botMessages[i - 1].id
      }

      if (i + 1 < botMessages.length) {
        // Link next message
        message.nextMessage = botMessages[i + 1].id
      }
    }
  }

  return botMessages
}

/**
 * Create bot and user message
 * @param {Object} dialog a dialog object
 * @param {Array<Object>} intentions
 * @returns {Object} of messages connected if applicable
 */
const _createBotAndUserMessage = (dialog /* intentions */) => {
  let response = {
    userMessage: undefined,
    botMessages: []
  }
  // support for old dialogs. Params is an object or has only one elem
  // const paramsLength = dialog.condition.params.length
  // const isArrayParam = Array.isArray(dialog.condition.params)
  // const isEntityMessage =
  //   isArrayParam && Object.keys(dialog.condition.params[0]).includes('entity')

  // if (!isEntityMessage && (!isArrayParam || paramsLength === 1)) {
  //   const regexDialog =
  //     dialog.condition.params.regex ||
  //     (dialog.condition.params[0] && dialog.condition.params[0].regex)
  //   if (regexDialog) {
  //     response.userMessage = _generateRegexUserMessage(dialog)
  //   }

  //   // support for old dialogs
  //   const intentDialog =
  //     dialog.condition.params.intent ||
  //     (dialog.condition.params[0] && dialog.condition.params[0].intent)
  //   if (intentDialog) {
  //     response.userMessage = _generateIntentUserMessage(
  //       intentDialog,
  //       intentions,
  //       dialog
  //     )
  //   }
  // } else {
  response.userMessage = _generateGenericUserMessage(dialog)
  // }

  response.botMessages = _generateBotMessages(dialog)

  // Link user and bot messages
  if (response.userMessage && response.botMessages.length > 0) {
    response.userMessage.nextMessage = response.botMessages[0].id
    response.botMessages[0].prevMessage = response.userMessage.id
  }

  return response
}

/**
 * Search next message
 * @param {String} nextDialogId the next dialog ID
 * @param {Array<Dialog>} dialogs the dialogs available
 * @returns {String} the next message ID
 */
const _searchNextMessage = (nextDialogId, dialogs) => {
  let nextDialog = _searchForDialogByDialogId(nextDialogId, dialogs)

  return nextDialog.idUserMessage
}

/**
 * Search previous message and retrieve ID
 * @param {String} dialogId the dialog ID of the previous message
 * @param {Array<Dialog>} dialogs the previous dialogs
 * @returns {String} the previous message ID
 */
const _searchPreviousMessage = (dialogId, dialogs) => {
  // Look for the dialog that has in the next field the current dialog ID
  let previousDialog = Vue._.find(dialogs, dialog => {
    return dialog.next === dialogId
  })

  if (previousDialog) {
    return previousDialog.idUserMessage
  }

  return undefined
}

/**
 * Flag the first child in order to determine the active path
 * @param {Array<Object>} dialogs the dialogs in the conversation
 * @param parentDialogId {String}
 */
const _retrieveFirstChildId = (dialogs, parentDialogId) => {
  let children = dialogs.filter(dialog => {
    return dialog.parent === parentDialogId
  })
  let dialogIds = children.map(dialog => dialog.dialogId)
  let nextRefs = children.map(dialog => dialog.next)
  let diff = Vue._.pullAll(dialogIds, nextRefs)

  return diff[0]
}

/**
 * Parse dialogs
 * @param {Array<Dialog>} dialogs the dialogs in the conversation
 * @param {Array<Object>} intentions the intentions created for the version
 * @return {Array<Messages>}
 */
// eslint-disable-next-line no-unused-vars
const _parseDialogs = (dialogs, intentions) => {
  let messages = []
  const dialogsMap = {}

  if (dialogs.length > 0) {
    dialogs = dialogs.map(d => {
      let botMessagesIds = []

      d.responses.forEach(() => {
        botMessagesIds.push(_generateID())
      })

      return Object.assign(d, {
        idBotMessages: botMessagesIds,
        idUserMessage: _generateID()
      })
    })

    // Si el primer diálogo no es un diálogo raíz no se construye bien
    // el árbol.
    // TO DO: ver por qué pasa esto y arreglarlo de verdad.
    const index = dialogs.findIndex(item => !item.parent)
    const copy = JSON.parse(JSON.stringify(dialogs[index]))
    dialogs.splice(index, 1)
    dialogs = [copy].concat(dialogs)

    // Load messages
    dialogs.forEach(d => {
      // apiCall will be array in the near future
      // d.apiCall = d.apiCall ? [d.apiCall] : []
      dialogsMap[d.dialogId] = d
      let msgTmp = _createBotAndUserMessage(d) // _createBotAndUserMessage(d, intentions)
      messages.push(msgTmp.userMessage)
      if (msgTmp.botMessages.length > 0) {
        messages = messages.concat(msgTmp.botMessages)
      }
    })

    for (let i = 0; i < dialogs.length; i++) {
      let dialogTmp = dialogs[i]
      let userMessage = Vue._.find(messages, m => {
        return m.id === dialogTmp.idUserMessage
      })

      // If the message has a sibling
      if (dialogTmp.next) {
        // Create alternative path for that sibling
        userMessage.nextAlternative = _searchNextMessage(
          dialogTmp.next,
          dialogs
        )
      }

      userMessage.prevAlternative = _searchPreviousMessage(
        dialogTmp.dialogId,
        dialogs
      )

      // if dialog is the first child then link previous message to the parent
      if (dialogTmp.parent) {
        let parentDialog = _searchForDialogByDialogId(dialogTmp.parent, dialogs)
        userMessage.prevMessage =
          parentDialog.idBotMessages[parentDialog.idBotMessages.length - 1] ||
          parentDialog.idUserMessage

        // Determine if it is the first child
        let firstChildDialogID = _retrieveFirstChildId(
          dialogs,
          parentDialog.dialogId
        )
        if (firstChildDialogID === dialogTmp.dialogId) {
          if (parentDialog.idBotMessages.length === 0) {
            let parentMessage = _searchMessage(
              messages,
              parentDialog.idUserMessage
            )
            parentMessage.nextMessage = userMessage.id
          } else {
            let parentMessage = _searchMessage(
              messages,
              parentDialog.idBotMessages[parentDialog.idBotMessages.length - 1]
            )

            parentMessage.nextMessage = userMessage.id
          }
        }
      }
    }

    messages.forEach(m => {
      _countRelatives(messages, m)
    })
  }

  return {
    messages,
    dialogsMap
  }
}

/**
 * Set the property second level when the intent is included in the bot intents
 * @param {Array<String>} botIntents the intents that will be escalated to a second level
 * @param {Array<Object>} intentions all the intentions available in the bot
 */
const _setIntentsScalesSecondLevel = (botIntents, intentions) => {
  if (botIntents && botIntents.length > 0) {
    for (let i = 0; i < intentions.length; i++) {
      let intention = intentions[i]
      intention.derivesToAgent = botIntents.includes(
        intention.name.toLowerCase()
      )
    }
  }
}

function _isCVCondition(type) {
  return type === FILTER_TYPES.variable
}

function _buildFirstFilter(message) {
  let condition = {}

  let filtersLength = message.filters.types.length
  if (filtersLength > 0) {
    const firstType = message.filters.types[0]
    const firstValue = message.filters.values[0]
    const firstValue2 = message.filters.values2[0]
    const firstCvType = message.filters.cvTypes[0]

    switch (firstType.type) {
      case FILTER_TYPES.intent:
        condition = {
          params: [
            {
              intent: firstType.text
            }
          ],
          tree: {
            funcs: [FILTER_TYPES.intent],
            facts: [null],
            operator: 'and'
          }
        }
        break

      case FILTER_TYPES.match:
        condition = {
          params: [
            {
              regex: firstValue
            }
          ],
          tree: {
            funcs: [FILTER_TYPES.match],
            facts: [null],
            operator: 'and'
          }
        }
        break

      case FILTER_TYPES.entity:
        condition = {
          params: [
            {
              entity: firstType.text,
              value: firstValue
            }
          ],
          tree: {
            funcs: [FILTER_TYPES.entity],
            facts: [null],
            operator: 'and'
          }
        }
        break

      case FILTER_TYPES.variable:
        if (CV_UNARY_FXS.includes(firstType.text)) {
          condition = {
            params: [
              {
                arg0: firstValue
              }
            ],
            tree: {
              funcs: [firstType.text],
              facts: [null],
              operator: 'and'
            }
          }
        } else {
          // isBinaryFunction
          condition = {
            params: [
              {
                type: firstCvType,
                arg0: firstValue,
                arg1: firstValue2
              }
            ],
            tree: {
              funcs: [firstType.text],
              facts: [null],
              operator: 'and'
            }
          }
        }
        break
      default:
        break
    }
  }

  return condition
}

function _buildCondition(message, conditionType, index) {
  let condition = {}

  if (conditionType.type === FILTER_TYPES.entity) {
    condition = {
      entity: conditionType.text,
      value: message.filters.values[index]
    }
  } else if (conditionType.type === FILTER_TYPES.match) {
    condition = {
      regex: message.filters.values[index]
    }
  } else if (conditionType.type === FILTER_TYPES.intent) {
    condition = {
      intent: conditionType.text
    }
  } else {
    // unary context variable
    if (CV_UNARY_FXS.includes(conditionType.text)) {
      condition = {
        arg0: message.filters.values[index]
      }
    } else {
      // binary context variable
      condition = {
        type: message.filters.cvTypes[index],
        arg0: message.filters.values[index],
        arg1: message.filters.values2[index]
      }
    }
  }

  return condition
}

function _buildFilters(condition, message) {
  const filtersLength = message.filters.types.length
  let newCondition = {}

  if (filtersLength > 1) {
    if (filtersLength === 2) {
      // one filter remaining
      const index = 1
      const conditionType = message.filters.types[index]
      newCondition = _buildCondition(message, conditionType, index)
      condition.params.push(newCondition)

      if (!_isCVCondition(conditionType.type)) {
        condition.tree.funcs.push(conditionType.type)
      } else {
        condition.tree.funcs.push(conditionType.text)
      }
      condition.tree.facts.push(null)
      condition.tree.operator = message.filters.operators[1]
    } else {
      let params = condition.params

      message.filters.types.forEach((filter, index) => {
        if (index !== 0) {
          newCondition = _buildCondition(message, filter, index)

          if (index > filtersLength - 2) {
            params.push(newCondition)
          } else {
            params.push([])
            params[1].push(newCondition)
            params = params[1]
          }
        }
      })

      condition.tree.funcs.push('marina')
      condition.tree.facts.push({})
      condition.tree.operator = message.filters.operators[1]

      let tree = condition.tree.facts[1]
      let funcs = []

      // build tree
      message.filters.types.forEach((filter, index) => {
        if (index !== 0) {
          if (!_isCVCondition(filter.type)) {
            funcs.push(filter.type)
          } else {
            funcs.push(filter.text)
          }

          if (index < filtersLength - 2) {
            funcs.push('marina')
            const nestedObject = {}
            tree.funcs = funcs
            tree.facts = [null]
            tree.operator = message.filters.operators[index + 1]
            funcs = []
            tree.facts.push(nestedObject)
            tree = nestedObject
          } else if (index < filtersLength - 1) {
            tree.facts = [null, null]
            tree.operator = message.filters.operators[index + 1]
          } else {
            tree.funcs = funcs
          }
        }
      })
    }
  }

  return condition
}

const _parseDialog = message => {
  let condition = _buildFirstFilter(message)

  // remove first filter
  // Object.keys(message.filters).forEach(field => {
  //   message.filters[field].splice(0, 1)
  // })

  // build remaining filters
  condition = _buildFilters(condition, message)

  return condition
}

const _isDefaultName = name => {
  return !name || SPECIAL_SYMBOLS.some(symbol => name.startsWith(symbol))
}

const _buildDialogName = (message, conditionName) => {
  if (!_isDefaultName(conditionName)) return conditionName

  let name = ''
  if (message && message.filters) {
    let value = ''
    let value2 = ''
    let cvType
    let op
    const filtersLength = message.filters.types.length

    message.filters.types.forEach((filter, index) => {
      value = message.filters.values[index]
      value2 = message.filters.values2[index]
      cvType = message.filters.cvTypes[index]
      op = index + 1 < filtersLength ? message.filters.operators[index + 1] : ''

      let symbol
      if (!_isCVCondition(filter.type)) {
        // symbol = TYPES_SYMBOLS[filter.type]
        // name = name.concat(symbol).concat(filter.text)
        name = name.concat(TYPES_SYMBOLS[filter.type]).concat(filter.text)
        name = value ? name.concat(`: ${value}`) : name
      } else {
        symbol = TYPES_SYMBOLS[filter.text]
        if (CV_UNARY_FXS.includes(filter.text)) {
          name = name.concat(`${symbol}($${value})`)
        } else {
          const cvTypes = cvType.split('&') // ['ctx', 'var', 'input']
          let currentCondName
          if (cvTypes[1] !== 'input') {
            currentCondName = `${
              CV_TYPES_SYMBOLS[cvTypes[0]]
            }${value} ${symbol} ${CV_TYPES_SYMBOLS[cvTypes[1]]}${value2}`
          } else {
            currentCondName = `$ctx ${symbol} ${
              CV_TYPES_SYMBOLS[cvTypes[1]]
            }${value}`
          }
          name = name.concat(currentCondName)
        }
      }

      name = name.concat(` ${op} \n`)
    })
  } else {
    //support for old non conditional messages
    name = message.type.name
  }

  return name.trim()
}

function _browserTimeZoneIndex() {
  let index = null
  index = timeZones.findIndex(
    item =>
      item.utc &&
      item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index === null || index === -1) {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    index = timeZones.findIndex(element => element.offset === offset)
  }
  return index
}

const _getBrowserTimeZone = () => {
  const browserTZ = _browserTimeZoneIndex()
  return browserTZ > -1 ? timeZones[browserTZ] : null
}

const _replaceSpecialCharacters = str => {
  const regExpr = /[^a-zA-Z0-9-_ ]/g
  return str.replace(/ /g, '_').replace(regExpr, '')
}

const _getKnownChannelType = type => {
  // example: whatsapp_botmaker => whatsapp
  return type.split('_')[0]
}

const parseChart = chartData => {
  const chartType = chartData.chartType

  let data = {}
  switch (chartType) {
    case 'pie':
      data = chartData.data && chartData.data[0] ? chartData.data[0][0] : {}
      break

    case 'bar':
      data = chartData.data && chartData.data[0] ? chartData.data[0] : {}
      data.forEach(bar => {
        if (bar._no_response) {
          bar['NS/NC'] = bar._no_response
          delete bar._no_response
        }
      })
      break

    case 'cluster':
      data = chartData.data || {}
      break

    default:
      break
  }

  if (data) {
    delete data._total
  }

  return {
    chartId: chartData.chartId,
    chartType,
    data
  }
}

export default {
  countRelatives: _countRelatives,
  generateDialog: _generateDialogToSave,
  generateID: _generateID,
  getAlternativePaths: _getAlternativePath,
  loadMessages: _loadConnectedMessages,
  parseDialogs: _parseDialogs,
  searchMessage: _searchMessage,
  searchMessageByResponseId: _searchMessageByResponseId,
  setIntentsScalesSecondLevel: _setIntentsScalesSecondLevel,
  updateRelatePathCounters: _updateRelatePathCounters,
  parseDialog: _parseDialog,
  buildDialogName: _buildDialogName,
  getBrowserTimeZone: _getBrowserTimeZone,
  replaceSpecialCharacters: _replaceSpecialCharacters,
  getKnownChannelType: _getKnownChannelType,
  parseChart
}
