import { BotService } from './bot/BotService'
import { NLU } from './bot/Nlu'
import { Availability } from './bot/Availability'
import { Bot } from './Bot'

export const generateBot = bot => {
  let service = new BotService(
    bot.service.name,
    bot.service.description,
    bot.service.users,
    bot.service._id,
    bot.service.usersByChannel
  )
  const platform = bot.nlu.platform
  let nlu = new NLU(platform, bot.nlu.culture)
  let availability = new Availability(
    bot.availability.days,
    bot.availability.exceptions,
    bot.availability.message
  )
  return new Bot(
    service,
    nlu,
    bot.noIntentMessage1,
    bot.noIntentAction,
    bot.intentConfidence,
    availability,
    bot.connectionErrorMessage,
    bot.intents,
    bot.intents,
    bot._id,
    bot._createdAt,
    bot._updatedAt,
    bot.legacy,
    bot.activeVersion,
    bot.passiveChat,
    bot.checkAvailability,
    bot.timeOffset,
    bot.defaultTakeoverMessage,
    bot.bypass,
    bot.inactivityTime,
    bot.inactivityMessage,
    bot.noIntentTimesToTakeOver,
    bot.platformsNoIntentTimesToTakeOver,
    bot.noIntentMessage2,
    bot.sessionTimeoutStatus0,
    bot.sessionTimeoutStatus1,
    bot.sessionTimeoutStatus2,
    bot.sessionTimeoutStatus3,
    bot.intentsByPlatform,
    bot.showEmotions,
    bot.disambiguationAction,
    bot.entityExtractor,
    bot.useAsTemplate,
    bot.notDialogDetected,
    bot.queues.filter(item => typeof item.queue === 'object'), // TECKO-1399 fix
  )
}
